<template>
	<BaseLayout>
		<template #header>
			<ViewHeader v-if="step != 3" :title="$t('ifeltanearthquake.title')" class="survey-title" fsize=""
				color="white" bcolor="#162A50" :showback="true">
			</ViewHeader>
		</template>
		<template #main>
			<div class="provo">
				<ProgressBar v-if="step != 3"></ProgressBar>
				<KeepAlive>
					<SurveyStep0 v-if="step == 0" :selected="survey.felt" @selected="survey.felt = $event" />
				</KeepAlive>
				<KeepAlive>
					<SurveyStep1 v-if="step == 1" :selected="survey.location" :survey="survey"
						@selected="handleStep2Selection" />
				</KeepAlive>
				<KeepAlive>
					<SurveyStep2 v-if="step == 2" :survey="survey" />
				</KeepAlive>
				<KeepAlive>
					<SurveyFinalStep v-if="step == 3" :id="id" />
				</KeepAlive>
				<SurveyButtons v-if="step != 3" :id="id" :survey="survey" />
			</div>
		</template>
	</BaseLayout>
</template>

<script>
import ProgressBar from '../components/ProgressBar.vue';
import SurveyStep0 from '../components/SurveyStep0.vue';
import SurveyStep1 from '../components/SurveyStep1.vue';
import SurveyStep2 from '../components/SurveyStep2.vue';
import SurveyFinalStep from '../components/SurveyFinalStep.vue';
import SurveyButtons from '../components/SurveyButtons.vue';

import { getDeviceId } from '../device-utils.js';

export default {
	components: {
		ProgressBar,
		SurveyStep0,
		SurveyStep1,
		SurveyStep2,
		SurveyFinalStep,
		SurveyButtons
	},
	props: {
		id: { type: String, required: true },
		step: { type: String, required: true }
	},
	data() {
		return {
			number: null,
			survey: {
				felt: null,
				location: null,
				currentlocation: true,
				deviceId: null
			}
		};
	},
	mounted() {
		document.body.style.backgroundColor = '#162A50';
	},
	unmounted() {
		document.body.style.removeProperty('background-color');
	},

	methods: {
		handleNavigation: function (val) {
			this.$router.push({
				name: 'Survey',
				params: { id: this.id, step: val }
			});
		},
		handleStep2Selection: function (val) {
			this.survey.location = val.location;
			this.survey.currentlocation = val.currentlocation;

			getDeviceId().then((deviceId) => {
				this.survey.deviceId = deviceId;
			});
		}
	}
};
</script>

<style>

</style>
