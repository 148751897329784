<template>
	<section>
		<div class="holder py-4">
			<Icon icon="	teenyicons:tick-circle-outline" class="check"></Icon>
			<p class="survey-border survey-text pb-3">
				{{ $t('ifeltanearthquake.finalStep.text1') }}
			</p>
			<p class=" survey-text">
				{{ $t('ifeltanearthquake.finalStep.text2') }}
			</p>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		id: { type: String, required: true }
	},

	mounted() {
		document.body.style.backgroundColor = '#162A50';
		setTimeout(() => {
			this.$router.push({
				name: this.$route.matched[this.$route.matched.length - 2].name,
				params: { id: this.id }
			});
		}, 3000);
	}
};
</script>

<style scoped>
.holder {
	border: 1.5px solid grey;
	box-shadow: 0 0 10px grey;
	border-radius: 10px;
	left: 50%;
	min-width: 286px;
	padding: 10px 45px;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 15%;
}

.survey-text{
	color: white;
	text-align: center;
	font-size: 18px;
	font-weight: 500;
}
.survey-border {
	border-bottom: 2px solid #bdb3b3;
}

.check {
	color: white;
	font-size: 60px;
	margin-bottom: 20px;
	width: 100%;
}

@media screen and (min-width: 280px) and (max-width: 360px) {
	.holder {
		min-width: 270px;
		padding-left: 30px;
		padding-right: 30px;
	}

	.check {
		font-size: 60px;
		margin-bottom: 15px;
	}
}
</style>
