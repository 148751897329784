<template>
	<div class="newspage">
		<img
			v-if="imageSrc && imageSrc"
			:src="imageSrc"
			class="d-block w-100"
		/>
		<img
			v-else
			src="/src/graphics/news-announcements.jpg"
			class="d-block w-100"
			alt="default-image"
		/>
		<div class="image_overlay">
			<div v-if="datetime || time" class="d-flex news-time">
				<span v-if="datetime" class="date col-6">
					<Icon
						icon="material-symbols:calendar-today-rounded"
						class="clock"
					/>
					<small class="text">{{ datetime }}</small>
				</span>

				<span v-if="time" class="clock col-6">
					<Icon icon="mdi:clock-time-four-outline" class="clock" />
					<small class="text">{{ time }}</small>
				</span>
			</div>

			<div class="title">{{ title }}</div>

			<div class="author">
				<!-- <img
						v-if="authorImage && authorImage"
						:src="authorImage"
						class="author_picture"
					/>
					<img
						v-else
						src="/src/graphics/profile.png"
						class="author_picture"
						alt="default-image"
					/> -->
				<span class="author_name">{{ authorName }}</span>
			</div>
		</div>
	</div>

	<div class="news-body">
		<!-- <div class="abstract" v-html="abstract"></div>
		<div class="border-bottom pb-3 mb-4"></div>
		<div class="content pb-2" v-html="content"></div> -->

		<div class="abstract" v-html="removeImageLinks(abstract)"></div>
		<div class="border-bottom pb-3 mb-4"></div>
		<div class="content pb-2" v-html="removeImageLinks(content)"></div>
	</div>
</template>

<script>
export default {
	props: {
		imageSrc: { type: String, default: null },
		title: { type: String, default: null },
		// authorImage: { type: String, default: null },
		authorName: { type: String, default: null },
		datetime: { type: String, default: null },
		time: { type: String, default: null },
		abstract: { type: String, default: null },
		content: { type: String, default: null }
	},
	mounted() {
		//EXECUTING THE JAVASCRIPT COMING FROM JSON METHOD ---- COMMENTED
		// Execute JavaScript code after the component is mounted
		// this.executeJavaScript();
	},
	methods: {
		//Removing a tags from V-HTML in abstract and content

		removeImageLinks(html) {
			// Replace the <a> tags wrapping the images with <span> tags
			return html.replace(/<a\b[^>]*>(.*?)<\/a>/gm, '<span>$1</span>');
		}

		//EXECUTING THE JAVASCRIPT COMING FROM JSON METHOD ---- COMMENTED
		// executeJavaScript() {
		// 	// Parse and execute JavaScript code from the abstract or content attribute
		// 	// This is just an example of how you could execute JavaScript code.
		// 	// Replace it with your actual logic if needed.
		// 	const abstractScript = document.createElement('script');
		// 	abstractScript.textContent = this.parseScriptFromHTML(
		// 		this.abstract
		// 	);
		// 	document.head.appendChild(abstractScript);
		// 	const contentScript = document.createElement('script');
		// 	contentScript.textContent = this.parseScriptFromHTML(this.content);
		// 	document.head.appendChild(contentScript);
		// },
		// parseScriptFromHTML(html) {
		// 	// Here you need to extract JavaScript code from the HTML content
		// 	// This is just a placeholder method, you may need to implement your own logic
		// 	// to extract and sanitize JavaScript code from the HTML content
		// 	// For example, you could use regular expressions to find and extract script tags
		// 	// and their content.
		// 	// This is a simplified example assuming the JavaScript code is enclosed within script tags.
		// 	const scriptPattern = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
		// 	const matches = html.match(scriptPattern);
		// 	if (matches) {
		// 		return matches
		// 			.map((match) => match.replace(/<\/?script>/g, ''))
		// 			.join('\n');
		// 	}
		// 	return '';
		// }
	}
};
</script>

<style scoped>
.newspage {
	position: relative;
}

.d-block {
	display: block;
	height: 320px;
	object-fit: cover;
}

.image_overlay {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	padding: 0px 30px;
	position: absolute;
	flex-direction: column;
	justify-content: center;
	background: rgba(0, 0, 0, 0.6);
}

.news-time {
	color: white;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

.clock {
	margin-right: 2px;
	padding-bottom: 2px;
}

.title {
	width: 100%;
	font-size: 20px;
	font-weight: 600;
	text-align: left;
	justify-content: center;
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	overflow: hidden;
	cursor: pointer;
	color: white;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
	margin-top: 15px;
	margin-bottom: 30px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
	.title {
		font-size: 20px;
	}
}

.author {
	width: 100%;
	padding-left: 15px;
	margin-bottom: 10px;
	color: white;
}

.author_picture {
	width: 27px;
	height: 25px;
	border-radius: 100%;
	margin-right: 5px;
}

.author_name {
	color: white;
}

.news-body {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-top: 20px;
	margin-right: auto;
	margin-left: auto;
	max-width: 1280px;
}

.abstract {
	font-style: normal;
	font-size: 16px;
	line-height: 30px;
	text-align: justify;
	letter-spacing: -0.02em;
	line-break: normal;
}

.image-template {
	position: sticky;
	margin: 20px;
}

.img-fluid {
	padding: 5px;
	bottom: 0;
}
</style>

<style>
.content a,
.abstract a {
	pointer-events: none !important;
}
</style>
