//earthquakesStore
import { defineStore } from 'pinia';
import { GetEarthquakes } from '../dataservice.js';
import { ref } from 'vue';

export const useEarthquakesStore = defineStore('earthquakes', () => {
	const earthquakes = ref([]);
	const earthquakesLoaded = ref(false);
	const earthquakesLoading = ref(false);
	const earthquakesError = ref(false);

	const last24Hours = ref([]);
	const last48Hours = ref([]);
	const last7Days = ref([]);

	const loadEarthquakes = async () => {
		earthquakesLoading.value = true;
		try {
			const response = await GetEarthquakes(3);
			earthquakes.value = response.data.features;
			earthquakesLoaded.value = true;
			earthquakesError.value = false;
			getEarthquakesByPeriod();
		} catch (error) {
			earthquakesError.value = true;
		} finally {
			earthquakesLoading.value = false;
		}
	};

	const getEarthquakesByPeriod = () => {
		last24Hours.value = [];
		last48Hours.value = [];
		last7Days.value = [];
		for (let i = 0; i <= earthquakes.value.length; i++) {
			const eq = earthquakes.value[i];
			const eqDate = new Date(eq.properties.time);
			const currentDate = new Date();
			if (
				eqDate.getTime() >=
				currentDate.getTime() - 24 * 60 * 60 * 1000
			) {
				last24Hours.value.push(eq);
			} else if (
				eqDate.getTime() >=
				currentDate.getTime() - 48 * 60 * 60 * 1000
			) {
				last48Hours.value.push(eq);
			} else {
				last7Days.value.push(eq);
			}
		}
	};

	loadEarthquakes();

	return {
		earthquakes,
		earthquakesLoaded,
		earthquakesLoading,
		earthquakesError,
		loadEarthquakes,
		last24Hours,
		last48Hours,
		last7Days
	};
});
