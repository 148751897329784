<template>
	<BaseLayout v-if="showTimeline">
		<template #header>
			<ViewHeader 
				title="Igeo" 
				:show-logo="true" 
				:actions="false" 
				:show-back="false"
			>
			</ViewHeader>
		</template>
		<template #main>
			<pull-refresh 
			v-model="loading" 
			:pulling-text="$t('timeline.loading')" 
			:loading-text="$t('timeline.loading')" 
			:success-text="$t('timeline.loading')" 
			:loosing-text="$t('timeline.loading')" 
			@refresh="loadEarthquakes()"
			>
				<TimelineBlock v-if="last24Hours.length != 0" :earthquakes="last24Hours"
					:label="$t('timeline.twentyfour')" />
				<TimelineBlock v-if="last48Hours.length != 0" :earthquakes="last48Hours"
					:label="$t('timeline.fortyeight')" />
				<TimelineBlock v-if="last7Days.length != 0" :earthquakes="last7Days" :label="$t('timeline.seven')" />
				<TimelineBlock v-if="last7Days.length == 0 && last48Hours.length == 0 && last24Hours.length == 0"
					:blank="$t('timeline.none')" />
			</pull-refresh>
			<spinner :show="earthquakesLoading"> </spinner>
		</template>
	</BaseLayout>
	<RouterView />
</template>

<script>
import image from '../assets/igeo-icone.png';
import TimelineBlock from '../components/TimelineBlock.vue';
import { useEarthquakesStore } from '../stores/earthquakesStore';
import { mapActions, mapState } from 'pinia';
import PullRefresh from 'pull-refresh-vue3';

export default {
	components: {
		TimelineBlock,
		PullRefresh
	},
	data() {
		return {
			image: image,
			count: 0,
			mesazhi: ' '
		};
	},
	computed: {
		...mapState(useEarthquakesStore, [
			'last24Hours',
			'last48Hours',
			'last7Days',
			'earthquakesLoaded',
			'earthquakesLoading',
			'earthquakesError'
		]),
		showTimeline() {
			return this.$route.name === 'timeline';
		},
		loading: {
			get() {
				return this.earthquakesLoading;
			},
			set(value) {
				//this.earthquakesLoading = value;
			}
		}
	},
	methods: {
		...mapActions(useEarthquakesStore, ['loadEarthquakes'])
	}
};
</script>
