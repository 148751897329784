<template>
	<Transition name="modal">
		<div v-if="show" class="modal-mask">
			<div class="modal-wrapper" @click="$emit('close')">
				<div class="modal-container">
					<div class="modal-header">
						<slot
							><h3>{{ $t('modal.title') }}</h3></slot
						>
						<slot
							><button @click="$emit('close')">
								<Icon
								class="pb-1"
									icon="ri:close-circle-line"
									:style="{ fontSize: '28px' }"
								/></button
						></slot>
					</div>

					<div class="modal-body">
						<div class="magnitude">
							<div class="w-100 emri">{{ $t('modal.mag') }}</div>
							<div class="icon-group">
								<div class="object">
									<svg
										id="Layer_1"
										version="1.1"
										xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										width="30px"
										height="30px"
										viewBox="0 0 40 40"
										enable-background="new 0 0 40 40"
										xml:space="preserve"
									>
										<circle
											fill="none"
											stroke="#231F20"
											stroke-width="0.7"
											stroke-miterlimit="10"
											cx="20"
											cy="20"
											r="5.499"
										/>
									</svg>
									<p>0 - 2.5</p>
								</div>
								<div class="object">
									<svg
										id="Layer_1"
										version="1.1"
										xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										width="30px"
										height="30px"
										viewBox="0 0 40 40"
										enable-background="new 0 0 40 40"
										xml:space="preserve"
									>
										<circle
											fill="none"
											stroke="#231F20"
											stroke-miterlimit="10"
											cx="20"
											cy="20"
											r="7.499"
										/>
									</svg>
									<p>2.5 - 4.0</p>
								</div>
								<div class="object">
									<svg
										id="Layer_1"
										version="1.1"
										xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										width="30px"
										height="30px"
										viewBox="0 0 40 40"
										enable-background="new 0 0 40 40"
										xml:space="preserve"
									>
										<circle
											fill="none"
											stroke="#231F20"
											stroke-miterlimit="10"
											cx="20"
											cy="20"
											r="12.833"
										/>
									</svg>
									<p>4.0 - 5.0</p>
								</div>
								<div class="object">
									<svg
										id="Layer_1"
										version="1.1"
										xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										width="30px"
										height="30px"
										viewBox="0 0 40 40"
										enable-background="new 0 0 40 40"
										xml:space="preserve"
									>
										<polygon
											fill="none"
											stroke="#231F20"
											stroke-miterlimit="10"
											points="20.112,2.288 25.868,13.949 38.737,15.819 29.426,24.897 
                        31.622,37.713 20.112,31.662 8.604,37.713 10.8,24.897 1.489,15.819 14.359,13.949 "
										/>
									</svg>
									<p>5.0 +</p>
								</div>
							</div>
						</div>

						<div class="depth">
							<div class="w-100 emri">
								{{ $t('modal.depth') }}
							</div>
							<div class="icon-group">
								<div class="object">
									<svg
										id="Layer_1"
										version="1.1"
										xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										width="30px"
										height="30px"
										viewBox="0 0 40 40"
										enable-background="new 0 0 40 40"
										xml:space="preserve"
									>
										<circle
											fill="#ff0000"
											stroke="#231F20"
											stroke-miterlimit="10"
											cx="20"
											cy="20"
											r="12.499"
										/>
									</svg>
									<p>0 - 15</p>
								</div>
								<div class="object">
									<svg
										id="Layer_1"
										version="1.1"
										xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										width="30px"
										height="30px"
										viewBox="0 0 40 40"
										enable-background="new 0 0 40 40"
										xml:space="preserve"
									>
										<circle
											fill="#ff7400"
											stroke="#231F20"
											stroke-miterlimit="10"
											cx="20"
											cy="20"
											r="12.499"
										/>
									</svg>
									<p>15 - 30</p>
								</div>
								<div class="object">
									<svg
										id="Layer_1"
										version="1.1"
										xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										width="30px"
										height="30px"
										viewBox="0 0 40 40"
										enable-background="new 0 0 40 40"
										xml:space="preserve"
									>
										<circle
											fill="#ffc100"
											stroke="#231F20"
											stroke-miterlimit="10"
											cx="20"
											cy="20"
											r="12.499"
										/>
									</svg>
									<p>30 +</p>
								</div>
							</div>
						</div>
					</div>

					<div class="modal-footer">
						<div>
							{{ $t('modal.a') }}
						</div>
						<div>
							{{ $t('modal.m') }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</Transition>
</template>

<script>
export default {
	props: {
		show: Boolean
	},
	emits: ['close']
};
</script>

<style scoped>
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
	padding-left: 50px;
	padding-right: 50px;
	width: 100%;
}

.modal-container {
	max-width: 602px;
	min-height: 360px;
	margin: 0px auto;
	padding: 25px;
	background-color: #f7f4f4;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
}

.modal-header {
	margin-top: 0;
}

button {
	border: none;
	background-color: #f7f4f4;
}

.modal-body {
	margin: 10px 0;
	font-size: 16px;
}

.modal-footer {
	font-size: 12px;
	font-style: italic;
	text-align: left;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
	opacity: 0;
}

.modal-leave-to {
	opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.w-100 {
	width: 100%;
}
.icon-group {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.emri {
	display: block;
	text-align: center;
	margin-bottom: 10px;
	font-weight: bold;
}
.magnitude {
	font-size: 13px;
}
.depth {
	font-size: 13px;
}
.object {
	align-items: center;
	text-align: center;
	margin: 0 10px;
}
</style>
