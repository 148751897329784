<template>
	<BaseLayout v-if="showNews">
		<template #header>
			<ViewHeader :title="$t('news.viewtitle')" :showhistoryback="true">
			</ViewHeader>
		</template>
		<template #main>
			<div class="body-content">
				<pull-refresh
					class="pull-refresh"
					v-model="isLoading"
					:pulling-text="$t('timeline.loading')"
					:loading-text="$t('timeline.loading')"
					:success-text="$t('timeline.loading')"
					:loosing-text="$t('timeline.loading')"
					@refresh="loadData()"
				>
					<div v-for="(news, index) in newspageData" :key="index">
						<CarouselComp
							v-if="index == 0"
							:image-src="news.properties.authorImage"
							:title="news.properties.title"
							:author-name="news.properties.authorName"
							:time="formatTime(news.properties.time)"
							@click="openNewsDetails(news.id)"
						></CarouselComp>
						<NewsComp
							v-if="index != 0"
							:image-src="news.properties.authorImage"
							:title="news.properties.title"
							:author-name="news.properties.authorName"
							:abstract="news.properties.abstract"
							:time="formatTime(news.properties.time)"
							:datetime="formatDate(news.properties.time)"
							@click="openNewsDetails(news.id)"
						></NewsComp>
					</div>
				</pull-refresh>
			</div>
			<spinner :show="isLoading"> </spinner>
		</template>
	</BaseLayout>
	<RouterView />
</template>

<script>
import CarouselComp from '../components/CarouselComp.vue';
import NewsComp from '../components/newscomp.vue';
import { GetNews } from '../dataservice';
import spinner from '../components/spinner.vue';
import PullRefresh from 'pull-refresh-vue3';

export default {
	components: {
		CarouselComp: CarouselComp,
		NewsComp: NewsComp,
		spinner,
		PullRefresh
	},
	data() {
		return {
			carouselData: [],
			newspageData: [],
			isLoading: false,
			showSpinner: true
		};
	},
	computed: {
		showNews() {
			return this.$route.name === 'news';
		}
	},
	created() {
		this.loadData();
	},
	methods: {
		loadData() {
			this.isLoading = true;
			GetNews()
				.then((response) => {
					this.newspageData = response.data.content;
					this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		formatDate(datetime) {
			if (datetime) {
				const date = new Date(datetime);
				let options = {
					day: 'numeric',
					month: 'short',
					year: 'numeric'
				};
				return new Intl.DateTimeFormat('en-GB', options).format(date);
			}
			return null;
		},
		formatTime(datetime) {
			if (datetime) {
				const time = new Date(datetime);
				let options = {
					hour: 'numeric',
					minute: 'numeric',
					hour12: true
				};
				return new Intl.DateTimeFormat('en-GB', options).format(time);
			}
			return null;
		},

		openNewsDetails(newsID) {
			let routename = this.$route.name == 'news' ? 'newspage' : 'news';
			this.$router.push({
				name: routename,
				params: { id: newsID }
			});
		}
	}
};
</script>

<style scoped>
.pull-refresh {
	height: 100%;
	margin: 10px;
	width: auto;
	overflow: unset !important;
}
</style>
