<template>
	<!-- <connectivity></connectivity> -->
	<RouterView />
</template>
<script>
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '@/stores/settingsStore.js';
import { mapState, mapActions } from 'pinia';
// import connectivity from './components/connectivity.vue';
import { addPhoneBackButtonListener } from './device-utils.js'; // Import the function

export default {
	components: {
		// connectivity
	},
	setup() {
		const settingsStore = useSettingsStore();
		const { language, terms } = storeToRefs(useSettingsStore());

		return {
			settingsStore,
			language,
			terms
		};
	},

	created() {
		this.redirectTimeline();
		addPhoneBackButtonListener(this.handleBackButton); // Pass the callback function here
	},

	beforeDestroy() {
		// Remove the listener when the component is destroyed
		App.removeAllListeners();
	},

	methods: {
		redirectTimeline() {
			if (!this.terms) {
				this.$router.push({ name: 'langselect' });
			}
		},
		handleBackButton() {
			// Handle the backButton event here
			this.$router.back();
		}
	},
	computed: {
		...mapState(useSettingsStore, ['terms']),
		...mapState(useSettingsStore, ['language'])
	}
};
</script>
