import { createI18n } from 'vue-i18n';
import en from './en.js';
import sq from './sq.js';
import { useLocalStorage } from '@vueuse/core';

const defaultLocale = 'sq';
const currentLocale = useLocalStorage('lang', defaultLocale);

const i18n = createI18n({
	legacy: false,
	locale: currentLocale.value,
	fallbackLocale: defaultLocale,
	messages: {
		en: en,
		sq: sq
	}
});

export default i18n;
