export default {
	apptitle: 'igeo Earhquake App',
	general: {
		en: 'English',
		sq: 'Shqip',
		timeline: 'Main Page'
	},
	firstview: {
		pranoni: 'To use IGEO App you need to carefully read and accept the',
		kushtet: 'Terms & Conditions',
		dhe: ' and',
		politiken: 'Privacy Policy',
		copyright: 'Copyright',
		allrights: 'Institute of GeoSciences. All rights reserved.',
		continue: 'Accept and Continue',
		warning: 'To continue please choose the language, read and accept Terms & Conditions and Privacy Policy.'
	},
	timeline: {
		twentyfour: 'Last 24 hours',
		fortyeight: 'Last 48 hours',
		seven: 'Last 7 days',
		none: 'No earthquake has occured the last 7 days.',
		depth: 'Depth',
		loading: 'Loading...'
	},
	earthquakedetails: {
		title: 'Earthquake',
		richter: 'Richter Scale',
		location: 'Location',
		depth: 'Depth',
		time: 'Date and Time',
		coordinates: 'Coordinates',
		last_update: 'Last update',
		event_type: 'Solution',
		felt: 'I felt this earthquake!',
		submitted: 'You have filled out the survey!'
	},
	share: {
		title: 'Share',
		text: 'Share this earthquake via:'
	},
	sharenews: {
		title: 'Share',
		text: 'Share this news via:'
	},
	footer: {
		timeline: 'Timeline',
		map: 'Map',
		news: 'News',
		archive: 'Archive',
		settings: 'Settings'
	},
	modal: {
		title: 'Legend',
		mag: 'Magnitude',
		depth: 'Depth (KM)',
		a: '[ A ] - Raw data arriving from the seismic activity monitoring sensors.',
		m: '[ M ] - Processed and refined data by seismology specialists at the IGEO Institute.'
	},
	news: {
		viewtitle: 'News'
	},
	settings: {
		terms: 'Terms and Conditions',
		policy: 'Privacy Policy',
		language: 'Language'
	},
	archive: {
		title: 'Archive',
		qarku: 'Region',
		periudha: 'Period of time',
		mag: 'Magnitude',
		clear: 'Clear',
		filter: 'Filter',
		qarku_placeholder: 'Select one',
		time_placeholder: 'Select time'
	},
	archivetimeline: {
		title: 'Filtered Earthquakes'
	},
	ifeltanearthquake: {
		title: 'I felt an earthquake',
		surveyStep0: {
			text: 'Please choose the picture that better represents the severity of the earthquake. ',
		},
		surveyStep1: {
			text: 'Please define the exact location from where you felt the earthquake.',
			radioButton1: 'Share your current location',
			radioButton2: 'Select from the map',
			selections: 'The selected coordinates are:',
			mapButtonBefore: 'Select this location',
			mapButtonAfter: 'Location Selected',
		},
		surveyStep2: {
			text: 'Your Selections: ',
			severityText: 'Severity ',
			locationText: 'Your location',
		},
		finalStep: {
			text1: 'Thank you for sharing. Your contribution will be processed by a seismologist.',
			text2: 'Redirecting you to the main page.'
		}
	}
};
