<template>
	<BaseLayout v-if="showLanguage">
		<template #header
			><ViewHeader
				:title="$t('settings.language')"
				:showhistoryback="true"
			>
			</ViewHeader>
		</template>
		<template #main>
			<div>
				<div
					class="d-flex language-item align-items-center"
					@click="changeLanguage('sq')"
				>
					<span class="name">{{ $t('general.sq') }}</span>
					<Icon
						v-if="language === 'sq'"
						icon="charm:tick"
						:style="{ fontSize: '30px' }"
						class="tick"
					/>
				</div>
				<div
					class="d-flex language-item align-items-center"
					@click="changeLanguage('en')"
				>
					<span class="name">{{ $t('general.en') }}</span>
					<Icon
						v-if="language === 'en'"
						icon="charm:tick"
						:style="{ fontSize: '30px' }"
						class="tick"
					/>
				</div>
			</div>
		</template>
	</BaseLayout>
	<RouterView />
</template>

<script>
import { useSettingsStore } from '../stores/settingsStore';
import { mapState, mapActions } from 'pinia';
export default {
	computed: {
		showLanguage() {
			return this.$route.name === 'language';
		},
		...mapState(useSettingsStore, ['language'])
	},
	methods: {
		...mapActions(useSettingsStore, ['changeLanguage'])
	}
};
</script>

<style scoped>
.language-item {
	width: 100%;
	height: 66px;
	background-color: #f1f1f1;
	border-bottom: 1px solid #d7d1d1;
	padding: 10px;
	justify-content: space-between;
}
.name {
	font-size: 20px;
	font-family: 'Montserrat';
	margin-top: 5px;
}
</style>
