<template>
	<div class="upper-navigation">
		<div v-if="showLogo">
			<img class="logo" :src="image" />
		</div>

		<div v-if="share" class="share">
			<Icon
				id="show-share"
				class="me-2"
				icon="ri:share-line"
				:style="{ fontSize: '25px' }"
				@click="sharePage"
			/>
		</div>

		<div v-if="title" class="text">
			{{ title }}
		</div>

		<div v-if="showback">
			<a
				@click="
					$router.push({
						name: getParent
					})
				"
			>
				<Icon icon="ri:arrow-left-s-line" class="arrow" />
			</a>
		</div>

		<div v-if="showhistoryback">
			<a @click="$router.back()">
				<Icon icon="ri:arrow-left-s-line" class="arrow" />
			</a>
		</div>

		<div v-if="reload" class="reload">
			<Icon
				id="show-share"
				class="me-2"
				icon="ant-design:reload-outlined"
				:style="{ fontSize: '25px' }"
				@click="reloadPage"
			/>
		</div>
	</div>
</template>
<script>
import image from '../assets/igeo-icone.png';
import spinner from './spinner.vue';
import { AtomSpinner } from 'epic-spinners';
import { shareURL } from '../device-utils';
import Modal from '../components/Modal.vue';

export default {
	components: {
		spinner,
		AtomSpinner,
		Modal
	},
	emits: ['reload'],
	props: {
		title: { type: [String, Boolean], default: 'View title' },
		actions: { type: Boolean, default: false },
		share: { type: Boolean, default: false },
		showback: { type: Boolean, default: false },
		showhistoryback: { type: Boolean, default: false },
		showLogo: { type: Boolean, default: false },
		showLegend: { type: Boolean, default: false },
		color: { type: String, default: 'black' },
		bcolor: { type: String, default: 'white' },
		fsize: { type: String, default: '21px' },
		reload: { type: Boolean, default: false }
	},
	data() {
		return {
			showShare: false,
			showSpinner: false,
			image: image
		};
	},
	computed: {
		getParent() {
			return this.$route.matched[this.$route.matched.length - 2].name;
		}
	},
	methods: {
		reloadPage() {
			this.$emit('reload');
		},
		async sharePage() {
			await shareURL({
				url:
					import.meta.env.VITE_APP_DEV_ROOT_URL + this.$route.fullPath
			});
		}
	}
};
</script>

<style scoped>
.actions,
.share {
	position: absolute;
	margin-top: 12px;
	right: 20px;
	width: 30px;
}
.legend {
	cursor: pointer;
	font-size: x-large;
}

.text {
	position: absolute;
	font-family: 'Inter', sans-serif;
	font-size: v-bind(fsize);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: v-bind(color);
}

.logo {
	height: 40px;
	padding-top: 10px;
	padding-left: 10px;
}
.arrow {
	font-size: 40px;
	margin-top: 7px;
	color: v-bind(color);
}

.reload {
	position: absolute;
	margin-top: 14px;
	right: 10px;
	width: 30px;
	cursor: pointer;
}

.share {
	position: absolute;
	margin-top: 15px;
	right: 10px;
	width: 30px;
	cursor: pointer;
}

.upper-navigation {
	border-bottom: 1px solid #d7d1d1;
	width: 100%;
	height: 55px !important;
	justify-content: space-between;
	display: flex;
	position: relative;
	top: 0;
}
</style>
