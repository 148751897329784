export default {
	apptitle: 'igeo Earhquake App',
	general: {
		en: 'English',
		sq: 'Shqip'
	},
	firstview: {
		pranoni: 'Per te perdorur aplikacionin IGEO lexoni me kujdes dhe pranoni',
		kushtet: 'Kushtet & Afatet',
		dhe: 'dhe',
		politiken: ' Politikën e Privatësisë',
		copyright: '',
		allrights:'Instituti i Gjeoshkencave. Të gjitha të drejtat e rezervuara.',
		continue: 'Prano dhe Vazhdo',
		warning: 'Për të vazhduar ju lutem zgjidhni gjuhën, lexoni dhe pranoni Termat & Kushtet dhe Politikën e Privatësisë.'
	},
	timeline: {
		twentyfour: '24 orët e fundit',
		fortyeight: '48 orët e fundit',
		seven: '7 ditët e fundit',
		none: 'Gjatë 7 ditëve të fundit nuk është regjistruar asnjë tërmet',
		depth: 'Thellësia',
		loading: 'Të dhënat po ngarkohen...'
	},
	earthquakedetails: {
		title: 'Tërmeti',
		richter: 'Shkallë Rihter',
		location: 'Vendndodhja',
		depth: 'Thellësia',
		time: 'Data dhe Ora',
		coordinates: 'Koordinatat',
		last_update: 'Përditësimi i fundit',
		event_type: 'Zgjidhja',
		felt: 'Unë e ndjeva këtë tërmet!',
		submitted: 'Ju e keni plotësuar pyetësorin!'
	},
	sharenews: {
		title: 'Shpërndaj',
		text: 'Shpërndaj këtë lajm nëpërmjet:'
	},
	earthquakeevents: {
		title: 'Titulli',
		mag: 'Magnitudaaaaaa',
		Depth: 'Depth'
	},
	footer: {
		timeline: 'Tërmetet',
		map: 'Harta',
		news: 'Lajmet',
		archive: 'Arkiva',
		settings: 'Cilësimet'
	},
	modal: {
		title: 'Legjenda',
		mag: 'Magnituda',
		depth: 'Thellësia (KM)',
		a: '[ A ] - Të dhëna të papërpunuara të cilat mbërrijnë nga sensorët e rrjetit të monitorimit të aktivitetit sizmik.',
		m: '[ M ] - Të dhëna të përpunuara dhe të saktësuara nga specialistët e sizmologjisë në Institutin IGEO.'
	},
	news: {
		viewtitle: 'Lajme'
	},
	settings: {
		terms: 'Termat and Kushtet e Përdorimit',
		policy: 'Politikat e Privatësisë',
		language: 'Gjuha'
	},
	ifeltanearthquake: {
		title: 'Ndjeva një tërmet',
		surveyStep0: {
			text: 'Ju lutem zgjidhni foton që përfaqëson më mirë shkallën e fortësisë së tërmetit ',
			buttonNext: 'Para',
			buttonPrevious: 'Mbrapa'

		},
		surveyStep1: {
			text: 'Ju lutem përcaktoni vendndodhjen e saktë nga ku e keni ndjerë tërmetin. ',
			radioButton1: 'Shpërndaj vendndodhjen aktuale',
			radioButton2: 'Përzgjidh nga harta',
			selections: 'Koordinatat e zgjedhura janë:',
			mapButtonBefore: 'Zgjidh këtë vendndodhje',
			mapButtonAfter: 'Vendodhja u zgjodh',

		},
		surveyStep2: {
			text: 'Zgjedhjet tuaja: ',
			severityText: 'Shkalla e fortësisë së tërmetit ',
			locationText: 'Vendodhja juaj',

		},
		finalStep: {
			text1: 'Faleminderit që e shpërndatë me ne! Kontributi juaj do të shqyrtohet nga një sizmiolog.',
			text2: ' Po ju ridrejtojmë tek faqja kryesore.'
		}
	}
};
