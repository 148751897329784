<template>
	<BaseLayout v-if="showMap">
		<template #header>
			<ViewHeader
				:title="$t('footer.map')"
				:showhistoryback="true"
				:reload="true"
				@reload="loadEarthquakes"
			>
			</ViewHeader>
		</template>
		<template #main>
			<l-map
				ref="MapFullView"
				class="map_container flex-fill"
				:zoom="zoom"
				:max-zoom="16"
				:min-zoom="5"
				:center="center"
				:max-bounds="maxBounds"
				:fade-animation="true"
				:zoom-control="true"
				:zoom-animation="true"
				:marker-zoom-animation="true"
				:no-blocking-animations="true"
				:options="{ zoomControl: true }"
				@update:center="centerUpdated"
			>
				<div>
					<l-control position="topright">
						<button
							type="button"
							class="btn btn-default"
							@click="showModal = true"
						>
							<Icon
								id="show-modal"
								icon="gis:map-legend-o"
								:style="{ fontSize: '26px', color: '#3A3A3A' }"
							/>
						</button>
						<Teleport to="body">
							<modal :show="showModal" @close="showModal = false">
							</modal>
						</Teleport>
					</l-control>
				</div>
				<l-control-layers
					position="topright"
					class="btn btn-default"
				></l-control-layers>

				<div>
					<l-control position="bottomright">
						<button
							type="button"
							class="btn btn-default"
							@click="setCenter()"
						>
							<Icon
								icon="carbon:center-circle"
								:style="{ fontSize: '26px', color: '#3A3A3A' }"
							/>
						</button>
					</l-control>
					<l-control position="bottomleft">
						<select v-model="selectedPeriod" class="select-area">
							<option
								class="option-area"
								v-for="period in periods"
								:key="period.value"
								:value="period.value"
							>
								{{ period.label }}
							</option>
						</select>
					</l-control>
				</div>

				<l-tile-layer
					v-for="tileProvider in tileProviders"
					:key="tileProvider.name"
					:name="tileProvider.name"
					:visible="tileProvider.visible"
					:url="tileProvider.url"
					:attribution="tileProvider.attribution"
					layer-type="base"
				/>

				<div>
					<l-marker
						v-for="(item, index) in selectedEarthquakes"
						:key="item.id"
						ref="item.id"
						:lat-lng="
							getMarkerCoordinates(item.geometry.coordinates)
						"
						:name="item.id"
						@click="openDetails(item.id)"
					>
						<l-icon v-if="item.properties.mag < '5' && index == 0">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								width="40"
								height="40"
								:fill="item.properties.markerColor"
								fill-opacity="0.7"
								stroke="#000000"
								class="blink"
							>
								<circle
									cx="20"
									cy="20"
									:r="
										getMarkerSize(
											item.properties.markerSize
										)
									"
								/>
							</svg>
						</l-icon>

						<l-icon
							v-else-if="item.properties.mag > '5' && index == 0"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								width="40px"
								height="40px"
								:fill="item.properties.markerColor"
								stroke="#000000"
								fill-opacity="0.7"
								class="blink"
							>
								<polygon
									points="20.112,4.19 25.25,14.599 36.737,16.268 28.426,24.371 30.386,35.811 20.112,30.41 9.839,35.811 11.8,24.371 3.489,16.268 14.977,14.599 "
								/>
							</svg>
						</l-icon>

						<l-icon v-else-if="item.properties.mag > '5'">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								width="40px"
								height="40px"
								:fill="item.properties.markerColor"
								stroke="#000000"
								fill-opacity="0.7"
							>
								<polygon
									points="20.112,4.19 25.25,14.599 36.737,16.268 28.426,24.371 30.386,35.811 20.112,30.41 9.839,35.811 11.8,24.371 3.489,16.268 14.977,14.599 "
								/>
							</svg>
						</l-icon>

						<l-icon v-else>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								width="40"
								height="40"
								:fill="item.properties.markerColor"
								fill-opacity="0.7"
								stroke="#000000"
							>
								<circle
									cx="20"
									cy="20"
									:r="
										getMarkerSize(
											item.properties.markerSize
										)
									"
								/>
							</svg>
						</l-icon>
					</l-marker>
				</div>
			</l-map>

			<spinner :show="earthquakesLoading || filteron"> </spinner>
		</template>
	</BaseLayout>
	<RouterView />
</template>

<script>
import 'leaflet/dist/leaflet.css';

// import MapComp from '../components/MapComp.vue';
import Modal from '../components/Modal.vue';
import { mapState, mapActions } from 'pinia';
import { useEarthquakesStore } from '../stores/earthquakesStore';
import {
	LMap,
	LIcon,
	LTileLayer,
	LMarker,
	LControlLayers,
	LControl
} from '@vue-leaflet/vue-leaflet';

export default {
	components: {
		LTileLayer,
		LMap,
		LIcon,
		LMarker,
		LControlLayers,
		LControl,
		Modal
	},
	data() {
		return {
			filteron: false,
			selectedPeriod: 'last24Hours', // Default selected period

			zoom: 7,
			maxBounds: [
				[26.98162674019255, 3.9868608154024514],
				[48.57497323662177, 31.869330316670627]
			],

			sizes: {
				small: '5',
				medium: '8',
				large: '12'
			},
			showModal: false,

			center: [41.15, 20.16],
			tileProviders: [
				{
					name: 'Esri Satellite',
					visible: true,
					url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
				},
				{
					name: 'OpenStreetMap',
					visible: false,
					url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
				},
				{
					name: 'CartoDBPositron',
					visible: false,
					url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
					subdomains: 'abcd'
				}
			]
		};
	},
	computed: {
		...mapState(useEarthquakesStore, [
			'last24Hours',
			'last48Hours',
			'last7Days',
			'earthquakesLoaded',
			'earthquakesLoading',
			'earthquakesError'
		]),

		periods() {
			return [
				{ label: this.$t('timeline.twentyfour'), value: 'last24Hours' },
				{ label: this.$t('timeline.fortyeight'), value: 'last48Hours' },
				{ label: this.$t('timeline.seven'), value: 'last7Days' }
			];
		},

		latestCenterHandler() {
			return [41.15, 20.16];
		},

		selectedEarthquakes() {
			if (this.selectedPeriod === 'last24Hours') {
				return this.last24Hours;
			} else if (this.selectedPeriod === 'last48Hours') {
				return [...this.last24Hours, ...this.last48Hours];
			} else {
				return [
					...this.last24Hours,
					...this.last48Hours,
					...this.last7Days
				];
			}
		},

		last48HoursEarthuqakes() {
			return [...this.last24Hours, ...this.last48Hours];
		},

		last7DaysEarthquakes() {
			// Concatenate all the earthquake arrays
			return [
				...this.last24Hours,
				...this.last48Hours,
				...this.last7Days
			];
		},

		showMap() {
			return this.$route.name === 'map';
		},
		loading: {
			get() {
				return this.earthquakesLoading;
			},
			set(value) {
				//this.earthquakesLoading = value;
			}
		}
	},
	methods: {
		centerUpdated(center) {
			this.center = center;
		},

		openDetails(earthquakeID) {
			let routename =
				this.$route.name == 'map' ? 'earthquakeDetailsMap' : 'details';
			this.$router.push({
				name: routename,
				params: { id: earthquakeID }
			});
		},

		setCenter() {
			this.center = [41.15, 20.16];
		},
		getMarkerSize(size) {
			return this.sizes[size];
		},

		getMarkerProperties(properties) {
			return properties;
		},

		getMarkerCoordinates(marker) {
			return [marker[1], marker[0]];
		},

		...mapActions(useEarthquakesStore, ['loadEarthquakes'])
	}
};
</script>

<style scoped>
.map_container {
	position: relative;
	margin-bottom: 0 !important;
	font-size: 8px !important;
}
.btn-default {
	background-color: white;
	width: 44px;
	height: 44px;
	padding: 0 0 0 0;
	margin-right: 3px;
	margin-left: 3px;
	border: 2px solid rgba(0, 0, 0, 0.329);
}
.btn-default:hover {
	background-color: rgba(255, 255, 255);
	border: 2px solid rgba(0, 0, 0, 0.329);
}

.blink {
	animation: blink 0.5s linear infinite;
}
@keyframes blink {
	0% {
		opacity: 0.2;
	}
	50% {
		opacity: 0.6;
	}
	100% {
		opacity: 1;
	}
}

.select-area {
	font-size: 14px;
	width: 110px;
	height: 20px;
	margin-bottom: 10px;
	margin-left: 2px;
}
.option-area {
	font-size: 12px;
	width: 70px;
	height: 20px;
}
</style>
