<template>
	<BaseLayout v-if="showSettings">
		<template #header>
			<ViewHeader
				:title="$t('footer.settings')"
				:showhistoryback="true"
			></ViewHeader>
		</template>
		<template #main>
			<RouterLink
				:to="{ name: 'language' }"
				style="text-decoration: none"
			>
				<div class="d-flex settings-item align-items-center">
					{{ $t('settings.language') }}
					<Icon
						icon="ri:arrow-right-s-line"
						class="arrow"
						:style="{ fontSize: '30px' }"
					/>
				</div>
			</RouterLink>

			<div
				class="d-flex settings-item align-items-center"
				@click="openBrowser(terms)"
			>
				<span class="name">{{ $t('settings.terms') }}</span>
			</div>

			<div
				class="d-flex settings-item align-items-center"
				@click="openBrowser(privacy)"
			>
				<span class="name">{{ $t('settings.policy') }}</span>
			</div>
		</template> </BaseLayout
	><RouterView />
</template>

<script>
import { Browser } from '@capacitor/browser';
import {
	logCurrentNetworkStatus,
	checkPermissions,
	requestPermissions
} from '../device-utils';

export default {
	data() {
		return {
			status: null,
			permission: null,
			requestedPermission: null,
			terms: import.meta.env.VITE_APP_TERMS_URL,
			privacy: import.meta.env.VITE_APP_PRIVACY_URL
		};
	},
	computed: {
		showSettings() {
			return this.$route.name === 'settings';
		}
	},
	created() {
		// this.conslogCurrentNetworkStatus();
		// this.logPermissionStatus();
		// this.requestPermission();
	},
	methods: {
		async conslogCurrentNetworkStatus() {
			this.status = await logCurrentNetworkStatus();
			console.log(this.status);
		},
		async logPermissionStatus() {
			this.permission = await checkPermissions();
			console.log(this.permission);
		},
		async requestPermission() {
			this.requestedPermission = await requestPermissions();
			console.log(this.requestedPermission);
		},

		async openBrowser(url) {
			await Browser.open({ url: url });
		}
	}
};
</script>

<style scoped>
.settings-item {
	width: 100%;
	height: 66px;
	background-color: #f1f1f1;
	border-bottom: 1px solid #d7d1d1;
	padding: 10px;
	justify-content: space-between;
	font-size: 20px;
	font-family: 'Montserrat';
	color: #000;
}
.outter-links {
	text-decoration: none;
}
</style>
