<template>
	<!-- Your selections -->
	<div class="container form py-4">
		<p class="survey-text">
			{{ $t('ifeltanearthquake.surveyStep2.text') }}
		</p>

		<!-- image-selected card -->
		<div v-if="selectedFelt" class="selection-cards">
			<p class="card-title">
				{{ $t('ifeltanearthquake.surveyStep2.severityText') }}
			</p>
			<div class="container card selection py-2">
				<div class="d-flex align-items-center">
					<img
						:src="selectedFelt.properties.image"
						class="selected-img"
					/>

					<!-- card-info language conditions -->
					<div v-if="language === 'sq'" class="card-body">
						<p class="card-info title">
							{{ selectedFelt.properties.titlesq }}
						</p>
					</div>
					<div v-if="language === 'en'" class="card-body">
						<p class="card-info title">
							{{ selectedFelt.properties.titleen }}
						</p>
					</div>

					<Icon
						class="icon me-3"
						icon="carbon:edit"
						:style="{ fontSize: '23px' }"
						@click="backToStep(0)"
					/>
				</div>
			</div>
		</div>

		<!-- location-selected card -->
		<div v-if="survey.location" class="selection-cards">
			<p class="card-title">
				{{ $t('ifeltanearthquake.surveyStep2.locationText') }}
			</p>
			<div class="container card selection">
				<div class="d-flex align-items-center">
					<div style="width: 75px">
						<Icon
							class="location-icon ps-1 mb-2 ms-3"
							icon="ri:map-pin-2-line"
							:style="{ fontSize: '40px' }"
						/>
					</div>
					<div class="card-body">
						<p class="card-info title">
							Lat: {{ survey.location[0].toFixed(3) }}
							<br />
							Lng: {{ survey.location[1].toFixed(3) }}
						</p>
					</div>

					<Icon
						class="icon me-3"
						icon="carbon:edit"
						:style="{ fontSize: '23px' }"
						@click="backToStep(1)"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapState } from 'pinia';
import { useSettingsStore } from '../stores/settingsStore';
export default {
	props: {
		survey: {
			type: Object,
			default: () => {
				return {};
			}
		}
	},
	data() {
		return {
			imagelist: []
		};
	},
	computed: {
		...mapState(useSettingsStore, ['language']),
		isSettingsMain() {
			return this.$route.name == 'SurveyStep0';
		},
		selectedFelt() {
			return this.survey.felt !== null
				? this.imagelist.find((item) => item.id == this.survey.felt)
				: null;
		}
	},
	created() {
		this.loadImages();
	},
	methods: {
		...mapActions(useSettingsStore, ['storeSurvey']),
		loadImages() {
			axios.get('/json/surveyImages.json').then((response) => {
				this.imagelist = response.data;
			});
		},
		postSurvey() {
			this.storeSurvey();
		},
		backToStep(step) {
			this.$router.push({ params: { step: step, back: 2 } });
		}
	}
};
</script>

<style scoped>
.card {
	height: 110px;
}

.form {
	background-color: #dfe0e2;
	border-radius: 10px;
	font-weight: 500;
	margin: 35px auto;
	max-width: 543px;
}
.survey-text {
	color: black;
	font-size: 22px;
	text-align: center;
	text-align-last: center;
	padding-right: 290px;
}
.card-title {
	color: black;
	font-size: 17px;
	padding-bottom: 7px;
}
.selected-img {
	border-radius: 10px;
}
.title {
	font-size: 17px;
	font-weight: 500;
	margin-top: 16px;
}
.selection-cards {
	justify-content: center;
	margin-bottom: 10px;
}
.selection {
	max-width: 450px;
}

.selected-img {
	height: 80px;
}

@media screen and (min-width: 320px) and (max-width: 354px) {
	.selection {
		max-width: 260px;
	}

	.title {
		font-size: 14px;
	}
	.selection-cards {
		margin-bottom: 0px;
	}

	.form {
		margin: 0px auto 15px;
		max-width: 280px;
	}

	.survey-text {
		font-size: 19px;
		padding-right: 110px;
	}
	.card-title {
		font-size: 15px;
		padding-bottom: 4px;
		padding-left: 5px;
	}
}

@media screen and (min-width: 355px) and (max-width: 375px) {
	.selection {
		max-width: 290px;
	}

	.title {
		font-size: 14px;
	}

	.form {
		margin: 0px auto 20px;
		max-width: 320px;
	}
	.selection-cards {
		margin-bottom: 3px;
	}

	.survey-text {
		font-size: 19px;
		padding-right: 150px;
	}
	.card-title {
		font-size: 15px;
		padding-bottom: 4px;
		padding-left: 5px;
	}
}

@media screen and (min-width: 376px) and (max-width: 425px) {
	.selection {
		max-width: 320px;
	}

	.title {
		font-size: 17px;
	}

	.form {
		margin: 0px auto 12px;
		max-width: 350px;
	}
	.selection-cards {
		margin-bottom: 7px;
	}
	.survey-text {
		font-size: 19px;
		padding-right: 180px;
	}
	.card-title {
		font-size: 15px;
		padding-bottom: 6px;
		padding-left: 5px;
	}
}

@media screen and (min-width: 426px) and (max-width: 530px) {
	.selection {
		max-width: 370px;
	}

	.title {
		font-size: 19px;
	}

	.form {
		margin: 0px auto 10px;
		max-width: 400px;
	}

	.survey-text {
		font-size: 19px;
		padding-right: 230px;
	}
	.card-title {
		font-size: 16px;
		padding-left: 6px;
	}
}

@media screen and (min-width: 531px) and (max-width: 600px) {
	.selection {
		max-width: 430px;
	}

	.title {
		font-size: 19px;
	}

	.form {
		margin: 20px auto 20px;
		max-width: 480px;
	}

	.survey-text {
		font-size: 20px;
		padding-right: 280px;
	}
	.card-title {
		font-size: 17px;
		padding-left: 15px;
	}
}
@media screen and (min-width: 601px) {
	.card-title {
		padding-left: 35px;
	}
}
</style>
