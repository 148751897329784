<template>
	<l-map
		ref="Map"
		class="map_container flex-fill"
		:zoom="zoom"
		:max-zoom="16"
		:min-zoom="5"
		:center="handledCenter"
		:max-bounds="maxBounds"
		:fade-animation="true"
		:zoom-control="true"
		:zoom-animation="true"
		:marker-zoom-animation="true"
		:no-blocking-animations="true"
		:options="{ zoomControl: true }"
		@update:center="centerUpdated"
	>
		<div v-if="showLegend">
			<l-control position="topright">
				<button
					type="button"
					class="btn btn-default"
					@click="showModal = true"
				>
					<Icon
						id="show-modal"
						icon="gis:map-legend-o"
						:style="{ fontSize: '26px', color: '#3A3A3A' }"
					/>
				</button>
				<Teleport to="body">
					<modal :show="showModal" @close="showModal = false">
					</modal>
				</Teleport>
			</l-control>
		</div>
		<l-control-layers
			position="topright"
			class="btn btn-default"
		></l-control-layers>

		<div v-if="recenterTop">
			<l-control position="bottomright">
				<button
					type="button"
					class="btn btn-default"
					@click="setCenter2()"
				>
					<Icon
						icon="carbon:center-circle"
						:style="{ fontSize: '26px', color: '#3A3A3A' }"
					/>
				</button>
			</l-control>
		</div>

		<!-- <l-control position="topright">
			<button
				type="button"
				class="btn btn-default"
				@click="generateImage()"
			>
				<Icon
					icon="material-symbols:screenshot"
					:style="{ fontSize: '26px', color: '#3A3A3A' }"
				/>
			</button>
		</l-control> -->

		<l-tile-layer
			v-for="tileProvider in tileProviders"
			:key="tileProvider.name"
			:name="tileProvider.name"
			:visible="tileProvider.visible"
			:url="tileProvider.url"
			:attribution="tileProvider.attribution"
			layer-type="base"
		/>

		<div v-if="showMarker">
			<l-marker :lat-lng="setCenter">
				<l-icon v-if="getProperties.mag > '5'">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						width="40px"
						height="40px"
						:fill="getProperties.markerColor"
						stroke="#000000"
						fill-opacity="0.7"
					>
						<polygon
							points="20.112,4.19 25.25,14.599 36.737,16.268 28.426,24.371 30.386,35.811 20.112,30.41 9.839,35.811 
						11.8,24.371 3.489,16.268 14.977,14.599 "
						/>
					</svg>
				</l-icon>
				<l-icon v-else>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						width="40px"
						height="40px"
						:fill="getProperties.markerColor"
						fill-opacity="0.7"
						stroke="#000000"
					>
						<circle
							cx="20"
							cy="20"
							:r="getMarkerSize(getProperties.markerSize)"
						/>
					</svg>
				</l-icon>
			</l-marker>
		</div>

		<div v-if="showMarkers">
			<l-marker
				v-for="(item, index) in last24Hours"
				:key="item.id"
				ref="item.id"
				:lat-lng="getMarkerCoordinates(item.geometry.coordinates)"
				:name="item.id"
				@click="openDetails(item.id)"
			>
				<l-icon v-if="item.properties.mag < '5' && index == 0">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						width="40"
						height="40"
						:fill="item.properties.markerColor"
						fill-opacity="0.7"
						stroke="#000000"
						class="blink"
					>
						<circle
							cx="20"
							cy="20"
							:r="getMarkerSize(item.properties.markerSize)"
						/>
					</svg>
				</l-icon>

				<l-icon v-else-if="item.properties.mag > '5' && index == 0">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						width="40px"
						height="40px"
						:fill="item.properties.markerColor"
						stroke="#000000"
						fill-opacity="0.7"
						class="blink"
					>
						<polygon
							points="20.112,4.19 25.25,14.599 36.737,16.268 28.426,24.371 30.386,35.811 20.112,30.41 9.839,35.811 11.8,24.371 3.489,16.268 14.977,14.599 "
						/>
					</svg>
				</l-icon>

				<l-icon v-else-if="item.properties.mag > '5'">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						width="40px"
						height="40px"
						:fill="item.properties.markerColor"
						stroke="#000000"
						fill-opacity="0.7"
					>
						<polygon
							points="20.112,4.19 25.25,14.599 36.737,16.268 28.426,24.371 30.386,35.811 20.112,30.41 9.839,35.811 11.8,24.371 3.489,16.268 14.977,14.599 "
						/>
					</svg>
				</l-icon>

				<l-icon v-else>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						width="40"
						height="40"
						:fill="item.properties.markerColor"
						fill-opacity="0.7"
						stroke="#000000"
					>
						<circle
							cx="20"
							cy="20"
							:r="getMarkerSize(item.properties.markerSize)"
						/>
					</svg>
				</l-icon>
			</l-marker>
		</div>
	</l-map>
</template>

<script>

import 'leaflet/dist/leaflet.css';
import leafletImage from 'leaflet-image';
import { mapState } from 'pinia';
import { useEarthquakesStore } from '../stores/earthquakesStore';
import Modal from '../components/Modal.vue';

import {
	LMap,
	LIcon,
	LTileLayer,
	LMarker,
	LControlLayers,
	LControl
} from '@vue-leaflet/vue-leaflet';
export default {
	name: 'LeafletMap',
	components: {
		LTileLayer,
		LMap,
		LIcon,
		LMarker,
		LControlLayers,
		LControl,
		Modal
	},
	props: {
		marker: { type: Array, default: null },
		properties: { type: Object, default: null },
		recenterTop: { type: Boolean, default: false },
		recenterBottom: { type: Boolean, default: false },
		showMarker: { type: Boolean, default: false },
		showMarkers: { type: Boolean, default: false },
		showLegend: { type: Boolean, default: false },
		center: { type: Array, default: () => [41.15, 20.16] }
	},
	data() {
		return {
			zoom: 7,
			internalCenter: [],
			maxBounds: [
				[26.98162674019255, 3.9868608154024514],
				[48.57497323662177, 31.869330316670627]
			],
			earthquakes: [],
			earthquakeID: [],
			size: null,
			showModal: false,
			sizes: {
				small: '5',
				medium: '8',
				large: '12'
			},
			tileProviders: [
				{
					name: 'Esri Satellite',
					visible: true,
					url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
				},
				{
					name: 'OpenStreetMap',
					visible: false,
					url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
				},
				{
					name: 'CartoDBPositron',
					visible: false,
					url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
					subdomains: 'abcd'
				}
			]
		};
	},

	computed: {
		...mapState(useEarthquakesStore, [
			'last24Hours',
			'last48Hours',
			'last7Days',
			'earthquakesLoaded',
			'earthquakesLoading',
			'earthquakesError'
		]),
		earthquake() {
			return this.$parent.earthquakes;
		},
		setCenter() {
			return this.marker ? [...this.marker].reverse() : [];
		},
		getProperties() {
			return this.properties;
		},
		handledCenter: {
			get() {
				return this.internalCenter
					? this.internalCenter
					: this.center;
			},
			set(value) {
				this.internalCenter = value;
			}
		}
	},

	created() {
		this.setCenter2();
	},
	mounted() {},
	methods: {
		centerUpdated(center) {
			this.internalCenter = center;
		},
		setCenter2() {
			this.internalCenter = this.marker ? [...this.marker].reverse() : [];
		},
		recenterMap() {
			this.$refs.Map.leafletObject.setView(this.center, 7);
		},
		getMarkerSize(size) {
			return this.sizes[size];
		},
		getMarkerCoordinates(marker) {
			return [marker[1], marker[0]];
		},
		getMarkerProperties(properties) {
			return properties;
		},
		openDetails(earthquakeID) {
			let routename =
				this.$route.name == 'map' ? 'earthquakeDetailsMap' : 'details';
			this.$router.push({
				name: routename,
				params: { id: earthquakeID }
			});
		},
		generateImage() {
			const map = this.$refs.Map.leafletObject;
			leafletImage(map, function (err, canvas) {
				var img = document.createElement('img');
				var dimensions = map.getSize();
				img.width = dimensions.x;
				img.height = dimensions.y;
				img.src = canvas.toDataURL();
				document.getElementById('images').innerHTML = '';
				document.getElementById('images').appendChild(img);
				console.log(img.src);
			});
		}
	}
};
</script>

<style>
.leaflet-control-layers-base {
	font-size: 12px;
	font-family: 'Montserrat';
	padding: 3px 10px;
}
.leaflet-base-layers_672 .leaflet-control-layers-selector {
	color:blueviolet !important;
	background-color: skyblue !important;
}
</style>

<style scoped>
.map_container {
	position: relative;
	margin-bottom: 0 !important;
	font-size: 8px !important;
}
.btn-default {
	background-color: white;
	width: 44px;
	height: 44px;
	padding: 0 0 0 0;
	margin-right: 3px;
	margin-left: 3px;
	border: 2px solid rgba(0, 0, 0, 0.329);
}
.btn-default:hover {
	background-color: rgba(255, 255, 255);
	border: 2px solid rgba(0, 0, 0, 0.329);
}
.blink {
	animation: blink 0.5s linear infinite;
}
@keyframes blink {
	0% {
		opacity: 0.2;
	}
	50% {
		opacity: 0.6;
	}
	100% {
		opacity: 1;
	}
}
</style>
