<template>
	<div>
		<div class="d-flex justify-content-center">
			<p class="survey-text">
				{{ $t('ifeltanearthquake.surveyStep0.text') }}
			</p>
		</div>
		<!-- This div contains the images we get from 'surveyImages.json' -->
		<div class="container-div">
			<div
				v-for="(item, index) in imageslist"
				:key="index"
				class="center-image"
			>
				<img
					:src="item.properties.image"
					class="imagecss"
					:alt="item.properties.title"
					:class="{ selected: selected === item.id }"
					@click="handleSelected(item.id)"
				/>
				<div class="overlay"></div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
export default {
	props: {
		selected: { type: String, required: true }
	},
	emits: ['selected'],
	data() {
		return {
			imageslist: []
		};
	},
	computed: {
		step() {
			return parseInt(this.$route.params.step);
		}
	},
	created() {
		this.loadData();
	},
	methods: {
		handleSelected: function (val) {
			this.$emit('selected', val);
			if (this.$route.params.back) {
				this.$router.push({
					params: { step: this.$route.params.back, back: null }
				});
			} else {
				this.$router.push({
					params: { step: this.step + 1, back: null }
				});
			}
		},
		loadData() {
			axios
				.get('/json/surveyImages.json')
				.then((response) => {
					this.imageslist = response.data;
				})
				.catch((error) => {
					// Handle the error
					console.error(error);
				});
		}
	}
};
</script>

<style scoped>
.survey-text {
	color: white;
	font-size: 17px;
	text-align: center;
	margin-left: 28px;
	margin-right: 28px;
}

.selected {
	filter: brightness(35%);
	position: relative;
}

.selected ~ .overlay {
	background-image: url(https://api.iconify.design/charm:circle-tick.svg?color=%23ffffff);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	bottom: 0;
	display: block;
	height: 140px;
	left: 0;
	margin: auto auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 140px;
	z-index: 12;
}

.container-div {
	align-items: center;
	display: flex;
	flex-direction: column;
}

.center-image {
	margin: 10px 0;
	position: relative;
}

.imagecss {
	border-radius: 7%;
	height: 285px;
	padding: 10px 10px 10px;
	width: auto;
}

@media screen and (min-width: 300px) and (max-width: 400px) {
	.imagecss {
		border-radius: 13%;
		height: 255px;
		padding: 10px 10px 10px;
		width: auto;
	}

	.container-div .center-image {
		margin: 0 auto;
	}
}
</style>
