<template>
	<div class="button-container container py-3">
		<!-- previous button -->
		<div>
			<button
				v-if="step > 0"
				type="button"
				class="btn btn-outline-success"
				@click="goback"
			>
				<Icon icon="ic:baseline-keyboard-arrow-left" class="arrow" />
			</button>
		</div>
		<!-- next button -->
		<div>
			<button
				v-if="step == 0 || step == 1"
				type="button"
				class="btn btn-success"
				:hidden="step == 0 && !enableNextButton"
				:disabled="!enableNextButton && step != 2"
				@click="gonext"
			>
				<Icon icon="ic:baseline-keyboard-arrow-right" class="arrow" />
			</button>
			<button v-if="step === 2" class="btn-success btn" @click="gonext">
				<Icon icon="ri:check-line" class="arrow" />
			</button>
		</div>
	</div>
</template>

<script>
import { useSettingsStore } from '../stores/settingsStore';
import { mapActions } from 'pinia';

export default {
	props: {
		id: { type: String, default: null },
		survey: {
			type: Object,
			default: () => {
				return {
					felt: null,
					location: null,
					deviceId: null
				};
			}
		}
	},
	computed: {
		step() {
			return parseInt(this.$route.params.step);
		},
		enableNextButton() {
			if (this.step == 0) {
				return this.survey.felt !== null;
			} else if (this.step == 1) {
				return this.survey.location !== null;
			}
			return false;
		}
	},
	methods: {
		...mapActions(useSettingsStore, ['storeSurvey']),
		goback() {
			this.$router.go(-1);
		},
		gonext() {
			if (this.step == 2) {
				this.storeSurvey(
					this.id,
					this.survey.felt,
					this.survey.location,
					this.survey.deviceId
				);
			}
			if (this.$route.params.back) {
				this.$router.push({
					params: { step: this.$route.params.back, back: null }
				});
			} else {
				this.$router.push({
					params: { step: this.step + 1, back: null }
				});
			}
		}
	}
};
</script>

<style scoped>
.button-container {
	bottom: 0;
	display: flex;
	justify-content: space-between;
	padding: 2% 12%;
	position: sticky;
}
.btn {
	border-radius: 50%;
	height: 40px;
	padding: 0px;
	text-align: center;
	width: 40px;
}

.btn-success {
	background-color: #22c55e;
	border-color: #22c55e;
	color: #fff;
}

.btn-outline-success {
	background-color: white;
	border-color: #22c55e;
	color: #22c55e;
}

.btn[data-v-d762a5a3]:first-child:active,
.btn-outline-success:focus {
	background-color: #22c55e;
	color: white;
}

.btn-success:focus,
.btn-success:hover,
.btn-outline-success:not(.btn-check) + .btn:active {
	background-color: #22c55e;
	border-color: transparent;
}

.btn-outline-success:hover,
.btn:first-child:active,
.btn-outline-success:focus {
	background-color: white;
	color: #22c55e;
}

.arrow {
	font-size: 30px;
}

@media screen and (min-width: 280px) and (max-width: 350px) {
	.button-container {
		padding: 1% 8%;
	}
}
</style>
