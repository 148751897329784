<template>
	<div class="baselayout d-flex flex-column">
		<div class="header">
			<slot name="header"></slot>
		</div>

		<div class="main">
			<slot name="main"></slot>
		</div>
		<div class="footer mt-auto">
			<div class="d-flex justify-content-start footer-elements">
				<div class="w-100 mw-25">
					<RouterLink class="icons" :to="{ name: 'timeline' }">
						<Icon
							icon="ri:align-left"
							:style="{ fontSize: '28px' }"
						/>
					</RouterLink>
					<div>
						<div class="icon-label">
							{{ $t('footer.timeline') }}
						</div>
					</div>
				</div>
				<div class="w-100 mw-25">
					<RouterLink class="icons" :to="{ name: 'map' }">
						<Icon
							icon="ri:map-2-line"
							:style="{ fontSize: '28px' }"
						/>
					</RouterLink>
					<div>
						<div class="icon-label">
							{{ $t('footer.map') }}
						</div>
					</div>
				</div>
				<div class="w-100 mw-25">
					<RouterLink class="icons" :to="{ name: 'news' }">
						<Icon
							icon="ri:newspaper-line"
							:style="{ fontSize: '28px' }"
						/>
					</RouterLink>
					<div>
						<div class="icon-label">
							{{ $t('footer.news') }}
						</div>
					</div>
				</div>

				<div class="w-100 mw-25">
					<RouterLink class="icons" :to="{ name: 'settings' }">
						<Icon
							icon="ri:settings-5-line"
							:style="{ fontSize: '28px' }"
						/>
					</RouterLink>
					<div>
						<div class="icon-label">
							{{ $t('footer.settings') }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<RouterView />
	</div>
</template>

<script>
export default {};
</script>

<style>
.baselayout {
	height: 100%;
	position: relative;
}

.main {
	height: 100%;
	overflow-y: scroll;
}

.footer {
	border-top: 1px solid #d7d1d1;
	width: 100%;
	background-color: #162a50;
	position: relative;
	bottom: 0;
}

.mw-20 {
	max-width: 25%;
}
.footer-elements {
	margin-top: 10px;
	margin-bottom: 14px;
	margin-left: 0px;
	margin-right: 0px;
	text-align: center;
	color: rgb(148, 144, 144);
	font-size: 11px;
}

.footer-elements a {
	text-decoration: none;
	color: #8e8d8d;
}

a.router-link-active {
	color: white;
}

a.router-link-active :hover {
	color: white;
}

.icons {
	color: grey;
}

.icons:hover {
	color: grey;
}
.icon-label {
	font-size: 12px;
}
.provo > * {
	animation: fadeInAnimation ease-in 0.4s;
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
