import { createRouter, createWebHashHistory } from 'vue-router';
import LangSelect from '../views/LangSelect.vue';
import Timeline from '../views/Timeline.vue';
import Mapp from '../views/Map.vue';
import News from '../views/News.vue';
import NewsPage from '../views/NewsPage.vue';
import Settings from '../views/Settings.vue';
import EarthquakeDetails from '../views/EarthquakeDetails.vue';
import Survey from '../views/Survey.vue';
import Language from '../views/Language.vue';

const router = createRouter({
	history: createWebHashHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'timeline',
			component: Timeline,
			children: [
				{
					path: ':id',
					name: 'earthquakeDetailsTimeline',
					component: EarthquakeDetails,
					props: true,
					children: [
						{
							path: 'survey/step/:step?/back/:back?',
							name: 'surveyTimeline',
							component: Survey,
							props: true
						}
					]
				}
			]
		},
		{
			path: '/map',
			name: 'map',
			component: Mapp,
			children: [
				{
					path: ':id',
					name: 'earthquakeDetailsMap',
					component: EarthquakeDetails,
					props: true,
					children: [
						{
							path: 'survey/step/:step?/back/:back?',
							name: 'surveyMap',
							component: Survey,
							props: true
						}
					]
				}
			]
		},
		{
			path: '/news',
			name: 'news',
			component: News,
			children: [
				{
					path: ':id',
					name: 'newspage',
					component: NewsPage,
					props: true
				}
			]
		},
		{
			path: '/settings',
			name: 'settings',
			component: Settings,
			props: true,
			children: [
				{
					path: 'language',
					name: 'language',
					component: Language,
					props: true
				}
			]
		},
		{
			path: '/langselect',
			name: 'langselect',
			component: LangSelect
		}
	]
});

export default router;
