<template>
	<div>
		<div class="d-flex earthquake">
			<div class="align-content-between flex-wrap col-5">
				<div class="title">{{ city }}</div>
				<div v-if="distance" class="distance">({{ distance }})</div>
				<div class="depth">
					{{ $t('timeline.depth') }} :
					<Icon icon="ri:arrow-down-line" class="depth" />{{ depth }}
				</div>
			</div>
			<div class="time col-3">
				<div>
					<Icon icon="ri:timer-2-line" class="clock" />{{ time }}
				</div>
			</div>
			<div class="time col-1">
				<div class="aorm" :class="aorm == 'M' ? 'green' : 'red'">
					[ {{ aorm }} ]
				</div>
			</div>
			<div class="magnitude col-1" :style="'background-color:' + bgcolor">
				{{ magnitude }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		city: { type: String, default: null },
		distance: { type: String, default: null },

		time: { type: String, default: null },
		depth: { type: String, default: null },
		aorm: { type: String, default: null },
		magnitude: { type: [String, Number], default: null },
		bgcolor: { type: String, default: null }
	},
	components: {}
};
</script>

<style scoped>
.earthquake {
	width: 100%;
	background-color: #f1f1f1;
	border-bottom: 2px solid #d7d1d1;
	padding: 5px;
	justify-content: space-between;
	min-height: 70px;
	height: 100%;
	align-items: center;
}
.title {
	color: #000000;
	margin-bottom: 0px;
	font-size: 17px;
	font-weight: 600;
	font-family: 'Montserrat';
	line-height: 1.2;
}
.time {
	color: #000000;
	font-size: 13px;
	text-align: left;
}
.clock {
	color: #000000;
	padding-right: 5px;
	font-size: 17px;
	margin-top: -3px;
}
.depth {
	position: relative;
	color: #000000;
	font-size: 13px;
	text-align: left;
	margin-top: -2px;
}
.distance {
	position: relative;
	color: #5c5c5c;
	font-size: 13px;
	text-align: left;
	margin-top: -2px;
}

.aorm {
	font-size: 12px;
}

.magnitude {
	height: 44px;
	width: 44px;
	border-radius: 50%;
	text-align: center;
	color: white;
	font-size: 28px;
}

.green {
	color: green;
}
.red {
	color: red;
}
</style>
