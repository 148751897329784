import axios from 'axios';
import { mapState, mapActions } from 'pinia';
import { useLocalStorage } from '@vueuse/core';

let axiosConfig = {
	headers: {
		'Content-Type': 'application/json'
	}
};

const appconfig = window.igeoclientconfig;

const rootpath = appconfig.urls.rootpath;

export const AppConfig = appconfig;

// /**
//  * Call for getting earthquakes data
//  * @returns axios prommise
//  */
// export const GetEarthquakes = (period) => {
// 	const url = rootpath + import.meta.env.VITE_APP_GET_URL;

// 	return axios.get(url, { ...axiosConfig });
// };

/**
 * Call for getting earthquakes data
 * @returns axios prommise
 */
export const GetEarthquakes = (period) => {
	const url = rootpath + import.meta.env.VITE_APP_GET_URL;

	return axios.get(url, { ...axiosConfig, params: { period: period } });
};

/**
 * Call for getting earthquake data details
 * @returns axios prommise
 */
export const GetEarthquakesDetails = (id) => {
	const url = rootpath + import.meta.env.VITE_APP_GET_DETAILS_URL;
	return axios.get(url, { ...axiosConfig, params: { id: id } });
};
/**
 * Call for getting news data
 * @returns axios prommise
 */
// export const GetNews = () => {
// 	const url = rootpath + import.meta.env.VITE_APP_GET_NEWS_URL;

// 	return axios.get(url, axiosConfig);
// };

export const GetNews = () => {
	const defaultLocale = 'sq';
	const currentLocale = useLocalStorage('lang', defaultLocale);

	const baseURL = import.meta.env.VITE_APP_GET_NEWS_URL;
	const languageFlag = currentLocale.value === 'en' ? 1 : 2;

	// Append language flag to the base URL
	const url = `${baseURL}&ln=${languageFlag}`;

	return axios.get(url);
};

/**
 * Call for posting earthquake survey data
 * @param {Object} data
 * @returns axios prommise
 * @example data = { id: 1, felt: 1, location: { lat: 1, lng: 1 }, date: new Date() }
 */
export const PostEarthquakeSurvey = (data) => {
	const url = rootpath + import.meta.env.VITE_APP_POST_URL;
	return axios.post(url, data, axiosConfig);
};
