<template>
	<div class="top_news">
		<img
			v-if="imageSrc && imageSrc"
			:src="imageSrc"
			class="d-block w-100 rounded-4"
		/>
		<img
			v-else
			src="/src/graphics/news-announcements.jpg"
			class="d-block w-100 rounded-4"
			alt="default-image"
		/>
		<div class="image_overlay">
			<div class="title">{{ title }}</div>
			<div v-if="time" class="d-flex news-time">
				<span class="author col-6">
					<!-- <img
						v-if="authorImage && authorImage"
						:src="authorImage"
						class="author_picture"
					/>
					<img
						v-else
						src="/src/graphics/profile.png"
						class="author_picture"
						alt="default-image"
					/> -->
					<span class="author_name">{{ authorName }}</span>
				</span>

				<span v-if="time" class="clock col-6">
					<Icon icon="mdi:clock-time-four-outline" class="clock" />
					<small class="text">{{ time }}</small>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		imageSrc: { type: String, default: null },
		title: { type: String, default: null },
		// authorImage: { type: String, default: null },
		authorName: { type: String, default: null },
		datetime: { type: String, default: null },
		time: { type: String, default: null },
		datetime: { type: String, default: null }
	}
};
</script>

<style scoped>
.top_news {
	position: relative;
}

.d-block {
	display: block;
	height: 260px;
	object-fit: cover;
	margin-bottom: 8px;
}

.image_overlay {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	padding: 20px 30px;
	position: absolute;
	border-radius: 12px;
	flex-direction: column;
	justify-content: center;
	background: rgba(0, 0, 0, 0.6);
}

.title {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 20px;
	text-align: left;
	justify-content: center;
	color: white;
	cursor: pointer;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.author {
	margin-right: auto;
	margin-left: auto;
	max-width: 1280px;
}

.author_picture {
	width: 27px;
	height: 25px;
	border-radius: 100%;
}

.author_name {
	color: white;
	font-size: 16px;
	padding-left: 5px;
}

.news-time {
	color: white;
	width: 100%;
	padding-right: 15px;
	margin-right: auto;
	margin-left: auto;
	max-width: 1280px;
}

.clock {
	padding-bottom: 2px;
	text-align: right;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
	.title {
		font-size: 17px;
	}
}

a {
	text-decoration-line: none;
	color: white;
}

a :hover {
	text-decoration-line: none;
	color: white;
}
</style>
