<template>
	<Transition name="modal">
		<div v-if="show" class="modal-mask">
			<div class="modal-wrapper" @click="closeModal">
				<div class="modal-container">
					<div class="map-container">
						<div class="survey-map">
							<l-map
								ref="Map"
								class="map_container"
								:zoom="zoom"
								:max-zoom="16"
								:min-zoom="7"
								:center="center"
								:fade-animation="true"
								:zoom-control="true"
								:zoom-animation="true"
								:marker-zoom-animation="true"
								:no-blocking-animations="true"
								:options="{ zoomControl: true }"
								@ready="onReady"
								@locationfound="onLocationFound"
								@move="handleMoove" 
							>
								<l-control-layers
									position="topright"
									class="btn btn-default layers"
								></l-control-layers>

								<l-tile-layer
									v-for="tileProvider in tileProviders"
									:key="tileProvider.name"
									:name="tileProvider.name"
									:visible="tileProvider.visible"
									:url="tileProvider.url"
									:attribution="tileProvider.attribution"
									layer-type="base"
								/>

								<template v-if="location">
									<l-circle-marker
										:lat-lng="location.latlng"
									/>
									<l-circle-marker
										:lat-lng="location.latlng"
									/>
								</template>

								<div class="map-marker">
									<img
										class="staycenter-marker-icon"
										src="../assets/marker.jpg"
									/>
								</div>
								<div class="overlay">
									<button
										class="btn location-btn"
										@click="returnData"
									>
										{{
											$t(
												'ifeltanearthquake.surveyStep1.mapButtonBefore'
											)
										}}
									</button>
								</div>
							</l-map>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Transition>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import {
	LMap,
	LTileLayer,
	LControlLayers,
	LCircleMarker
} from '@vue-leaflet/vue-leaflet';

export default {
	name: 'LeafletMap',
	components: {
		LTileLayer,
		LMap,
		LControlLayers,
		LCircleMarker
	},
	props: {
		marker: { type: Array, default: null },
		properties: { type: Object, default: null },
		show: Boolean
	},
	emits: ['return-data', 'close'],
	data() {
		return {
			zoom: 7,
			center: [41.12, 19.8],
			earthquakes: [],
			location: null,
			size: null,
			sizes: {
				small: '5',
				medium: '8',
				large: '12'
			},
			tileProviders: [
				{
					name: 'Esri Satellite',
					visible: true,
					url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
				},
				{
					name: 'OpenStreetMap',
					visible: false,
					url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
				},
				{
					name: 'CartoDBPositron',
					visible: false,
					url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
					subdomains: 'abcd'
				}
			]
		};
	},
	computed: {
		earthquake() {
			return this.$parent.earthquakes;
		},
		setCenter() {
			return this.marker ? [...this.marker].reverse() : [];
		},
		getProperties() {
			return this.properties;
		}
	},
	methods: {
		returnData() {
			let data = {
				center: this.center
			};
			this.$emit('return-data', data);
			this.$emit('close');
		},
		handleMoove(event) {
			this.center = event.target.getCenter();
		},
		getMarkerSize(size) {
			return this.sizes[size];
		},
		getMarkerCoordinates(marker) {
			return [marker[1], marker[0]];
		},
		getMarkerProperties(properties) {
			return properties;
		},
		onReady(themap) {
			this.map = themap;
			this.map.locate();
		},
		onLocationFound(l) {
			this.location = l;
			this.center = l.latlng;
		},
		closeModal(event){
			if(event.target === event.currentTarget){
				this.$emit('close');
			}
		}
	}
};
</script>

<style scoped>
.map-container {
	height: 100%;
	width: 100%;
}

.survey-map {
	width: 100%;
	height: 100%;
}

.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
	padding: 100px 25px;
	width: 100%;
}

.modal-container {
	width: 100%;
	height: 100%;
	background-color: #f7f4f4;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
}

button {
	border: none;
	background-color: #f7f4f4;
}

.modal-enter-from {
	opacity: 0;
}

.modal-leave-to {
	opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.btn {
	background-color: transparent;
	color: white;
	font-weight: bolder;
}

.overlay {
	z-index: 999;
	position: absolute;
	bottom: 0;
	font-size: 15px;
	left: 50%;
	transform: translate(-50%, -50%);
}

.location-btn {
	background-color: #162a50;
	color: white;
	width: 320px;
	height: 45px;
	font-size: max(1vw, 18px);
	border: 1px solid rgba(255, 255, 255, 0.478);
}

.btn:hover {
	background-color: #162a50;
	color: white;
}

.map_container {
	width: 100%;
	font-size: 8px !important;
}

.staycenter-marker-icon {
	position: absolute !important;
	top: calc(50% - 20px) !important;
	left: 50% !important;
	transform: translate(-50%, -50%);
	margin-bottom: 10px;
	display: block !important;
	background-color: transparent;
	z-index: 999 !important;
	background-size: cover;
	background-image: url('../assets/img/marker.jpg');
	width: 50px !important;
	height: 50px !important;
	margin: 0 !important;
	content: '';
}

@media screen and (min-width: 280px) and (max-width: 360px) {
	.location-btn {
		width: 250px;
		height: 37px;
		font-size: 15px;
	}
}
</style>
