<template>
	<div class="body">
		<div class="d-flex align-items-center flex-column select-lang-area">
			<button
				class="sq"
				:class="language == 'sq' ? 'autofocus' : ''"
				@click="changeLanguage('sq'), checked()"
			>
				{{ $t('general.sq') }}
			</button>
			<button
				class="en"
				:class="language == 'en' ? 'autofocus' : ''"
				@click="changeLanguage('en'), checked()"
			>
				{{ $t('general.en') }}
			</button>
			<p class="warning" v-if="warning == true">
				{{ $t('firstview.warning') }}
			</p>
		</div>
		<div class="select-language">
			<div class="terms">
				{{ $t('firstview.pranoni') }}
				<span class="underline" @click="openBrowser(termsurl)">
					{{ $t('firstview.kushtet') }}
				</span>
				{{ $t('firstview.dhe') }}
				<span class="underline" @click="openBrowser(privacyurl)">
					{{ $t('firstview.politiken') }}
				</span>
			</div>

			<RouterLink :to="{ name: 'timeline' }" class="bigrouter">
				<button class="accept" @click="hasCheckedTerms">
					{{ $t('firstview.continue') }}
				</button>
			</RouterLink>
			<div class="copyright">
				{{ $t('firstview.copyright') }} &copy;
				{{ new Date().getFullYear() }}
				{{ $t('firstview.allrights') }}
			</div>
		</div>
	</div>
</template>

<script>
import { useSettingsStore } from '../stores/settingsStore';
import { mapState, mapActions } from 'pinia';
import { Browser } from '@capacitor/browser';
export default {
	data() {
		return {
			check: false,
			warning: false,
			termsurl: import.meta.env.VITE_APP_TERMS_URL,
			privacyurl: import.meta.env.VITE_APP_PRIVACY_URL,
			kusht: false,
			privatesi: false
		};
	},

	computed: {
		...mapState(useSettingsStore, ['terms']),
		...mapState(useSettingsStore, ['language'])
	},
	methods: {
		...mapActions(useSettingsStore, ['hasCheckedTerms']),
		...mapActions(useSettingsStore, ['changeLanguage']),

		checked() {
			this.check = true;
		},
		showwarning() {
			this.warning = true;
		},
		async openBrowser(url) {
			await Browser.open({ url: url });
		}
	}
};
</script>

<style scoped>
.body {
	height: 100%;
	margin: 0;
	position: relative;
	background-image: url('/src/graphics/SplashScreen3.png');
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}

.select-lang-area {
	bottom: 230px;
	width: 100%;
	display: flex;
	padding: 0px 20px;
	position: fixed;
	flex-direction: column;
	text-align: center;
	justify-content: center;
}
.select-language {
	bottom: 10px;
	width: 100%;
	display: flex;
	padding: 0px 20px;
	position: fixed;
	flex-direction: column;
	text-align: center;
	justify-content: center;
}

.sq,
.en {
	background-color: #3c4e6e;
	border: 1px solid #142e5c;

	font-size: 16px;
	min-width: 100px;
	max-width: 320px;
	height: 40px;
	border-radius: 7px;
	position: relative;
	width: 100%;
	color: #ffffff;
	font-weight: bold;
	margin-bottom: 20px;
}
.autofocus {
	/* background-color: #3c4e6e;
	color: #ffffff;
	outline:none; */
	background-color: #e7e6e6d0;
	border: 3px solid #ffffff;
	color: #142e5c;
}

.warning {
	color: #ff0000;
	font-size: 13px;
	font-weight: 400;
}

.terms {
	font-size: 14px;
	color: #ffffff;
	font-weight: 400;
	margin-top: 70px;
	margin-bottom: 20px;
}

.accept {
	background-color: #142e5c;
	border: 1px solid #ffffff;
	color: white;
	font-size: 16px;
	min-width: 100px;
	max-width: 320px;

	height: 40px;
	border-radius: 7px;
	position: relative;
	width: 100%;
	margin-top: auto;
	margin-bottom: 20px;
}

.copyright {
	color: white;
	font-size: 12px;
	width: 100%;
	text-align: center;
	margin: 0 auto;
}

a {
	text-decoration-line: none;
	color: white;
	text-decoration: underline;
}

.bigrouter {
	text-decoration: none;
}

.underline {
	color: rgb(106, 193, 252);
	text-decoration: underline;
}
</style>
