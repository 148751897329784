<template>
	<div>
		<div class="label" v-if="label">{{ label }}</div>
		<div class="blank" v-if="blank">{{ blank }}</div>
		<div v-for="(item, index) in earthquakes" :key="index">
			<RouterLink
				style="text-decoration: none"
				:to="{
					name: getChild,
					params: { id: item.id }
				}"
			>
				<EarthquakeComp
					class="earthquake-comp"
					:city="item.properties.location_city"
					:distance="item.properties.location_distance"
					:time="formatDate(item.properties.time)"
					:depth="item.properties.depth + ' km'"
					:aorm="item.properties.event_type"
					:magnitude="item.properties.mag"
					:bgcolor="item.properties.markerColor"
				></EarthquakeComp>
			</RouterLink>
		</div>
	</div>
</template>

<script>
import EarthquakeComp from '../components/earthquakecomp.vue';
export default {
	name: 'TimelineBlock',
	components: {
		EarthquakeComp
	},
	props: {
		earthquakes: {
			type: Array,
			default() {
				return [];
			}
		},
		label: {
			type: String,
			default: ''
		},
		blank: {
			type: String,
			default: ''
		}
	},
	computed: {
		getChild() {
			return this.$route.matched[this.$route.matched.length - 1]
				.children[0].name;
		}
	},

	methods: {
		formatDate(time) {
			const date = new Date(time);
			let options = {
				day: 'numeric',
				month: 'short',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric',
				hour12: true
			};
			return new Intl.DateTimeFormat('en-GB', options).format(date);
		}
	}
};
</script>

<style scoped>
.label {
	width: 100%;
	height: 30px;
	text-align: center;
	font-family: 'Montserrat';
	font-size: 18px;
	color: white;
	background-color: #162a50;
	padding-top: 3px;
}

.blank {
	left: 0;
	margin-top: -100px;
	position: absolute;
	text-align: center;
	top: 50%;
	width: 100%;
}
</style>
