<template>
	<BaseLayout v-if="shownewspage">
		<template #header>
			<ViewHeader
				:title="$t('news.viewtitle')"
				:showback="true"
				:share="true"
			>
			</ViewHeader>
		</template>
		<template #main>
			<pull-refresh
				v-model="isLoading"
				:pulling-text="$t('timeline.loading')"
				:loading-text="$t('timeline.loading')"
				:success-text="$t('timeline.loading')"
				:loosing-text="$t('timeline.loading')"
				@refresh="loadData()"
			>
				<NewspageComp
					v-if="newslist"
					:title="newslist.properties.title"
					:image-src="newslist.properties.authorImage"
					:author-name="newslist.properties.authorName"
					:datetime="formatDate(newslist.properties.time)"
					:time="formatTime(newslist.properties.time)"
					:abstract="newslist.properties.abstract"
					:content="newslist.properties.content"
				></NewspageComp>
			</pull-refresh>

			<spinner :show="isLoading" />
		</template>
	</BaseLayout>
	<RouterView />
</template>

<script>
import NewspageComp from '../components/NewspageComp.vue';
import { GetNews } from '../dataservice';
import { ref } from 'vue';
import VueEasyLightbox from 'vue-easy-lightbox';
import axios from 'axios';
import spinner from '../components/spinner.vue';
import PullRefresh from 'pull-refresh-vue3';

export default {
	components: {
		NewspageComp: NewspageComp,
		VueEasyLightbox,
		spinner,
		PullRefresh
	},
	props: {
		id: { type: String, required: true }
	},
	setup() {
		const visibleRef = ref(false);
		const indexRef = ref(0);
		const showImg = (index) => {
			indexRef.value = index;
			visibleRef.value = true;
		};
		const onHide = () => (visibleRef.value = false);
		return {
			visibleRef,
			indexRef,
			showImg,
			onHide
		};
	},
	data() {
		return {
			newsID: [],
			newspageData: [],
			imgs: [],
			isLoading: false,
			showSpinner: true
		};
	},

	computed: {
		boxImg() {
			let list = [];
			if (this.imgs) {
				list = this.imgs.map((photo) => photo.properties.image);
			}
			return list;
		},
		getnewsID() {
			let newsID = null;
			newsID = this.$route.path.slice(1);
			return newsID;
		},

		newslist() {
			return this.newspageData.find((news) => {
				return news.id === this.id;
			});
		},

		shownewspage() {
			return this.$route.name === 'newspage';
		}
	},
	created() {
		this.loadData();
	},
	methods: {
		loadData() {
			this.isLoading = true;
			GetNews()
				.then((response) => {
					this.newspageData = response.data.content;
					this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		formatDate(datetime) {
			if (datetime) {
				const date = new Date(datetime);
				let options = {
					day: 'numeric',
					month: 'short',
					year: 'numeric'
				};

				return new Intl.DateTimeFormat('en-GB', options).format(date);
			}
			return null;
		},
		formatTime(datetime) {
			if (datetime) {
				const time = new Date(datetime);
				let options = {
					hour: 'numeric',
					minute: 'numeric',
					hour12: true
				};

				return new Intl.DateTimeFormat('en-GB', options).format(time);
			}
			return null;
		}
	}
};
</script>

<style scoped>
img {
	width: 100%;
	vertical-align: middle;
	border-style: none;
	object-fit: cover;
}

.row {
	padding: 10px;
	width: 100%;
	object-fit: fill;
	padding-right: 15px;
	padding-left: 15px;
	margin-top: 20px;
	margin-right: auto;
	margin-left: auto;
	max-width: 1280px;
}

.border-bottom {
	border-bottom: 1px solid #dee2e6 !important;
}

.vel-modal {
	background: rgba(0, 0, 0, 0.8);
}
.vel-modal .vel-img-wrapper img .vel-img {
	width: 320px !important;
}
</style>
