<template>
	<div class="news-card rounded-left">
		<div class="d-flex image">
			<div class="image col-4 rounded-left">
				<img v-if="imageSrc && imageSrc" :src="imageSrc" class="img" />
				<img
					v-else
					src="/src/graphics/news-announcements.jpg"
					class="img"
					alt="default-image"
				/>
			</div>
			<div class="news-info col-8">
				<div class="title">{{ title }}</div>

				<div v-if="datetime || time" class="d-flex news-time">
					<div class="date col-6">
						<Icon
							icon="material-symbols:calendar-today-rounded"
							class="calendar-icon"
						/>
						<small class="text">{{ datetime }}</small>
					</div>

					<span class="clock col-6">
						<Icon
							icon="mdi:clock-time-four-outline"
							class="clock-icon"
						/>
						<small class="text">{{ time }}</small>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		imageSrc: { type: String, default: null },
		title: { type: String, default: null },
		// authorImage: { type: String, default: null },
		authorName: { type: String, default: null },
		datetime: { type: String, default: null },
		time: { type: String, default: null },
		abstract: { type: String, default: null }
	}
};
</script>

<style scoped>
.news-card {
	position: relative;
	color: black;
	height: 100%;
	background-color: #f0f0f0;
	box-shadow: 0px 0px 28px -15px rgba(0, 0, 0, 0.48);
	border: 1px solid #d9d9d9;
	margin-top: 12px;
	border-radius: 6px;
	padding: 0;
}
.news-info {
	padding: 8px;
}
.img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	height: 115px;
}

.element-style {
	max-height: 300px;
	overflow: hidden;
}

.title {
	font-size: 22px;
	font-weight: bold;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	cursor: pointer;
}

.calendar-icon {
	margin-right: 2px;
	padding-bottom: 2px;
}
.date,
.clock {
	position: absolute;
	bottom: 0;
	margin-bottom: 5px;
}
.clock {
	text-align: right;
}
.clock-icon {
	margin-right: 2px;
	padding-bottom: 2px;
}

@media screen and (min-width: 320px) and (max-width: 580px) {
	.element-style {
		max-height: 200px;
	}

	.title {
		font-size: 16px;
	}

	.date,
	.clock {
		font-size: 14px;
	}
}

@media screen and (min-width: 581px) and (max-width: 1007px) {
	.title {
		font-size: 20px;
	}

	.date,
	.clock {
		font-size: 16px;
	}
}

a {
	text-decoration-line: none;
	color: black;
}

a :hover {
	text-decoration-line: none;
	color: black;
}
</style>
