import { Geolocation } from '@capacitor/geolocation';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Network } from '@capacitor/network';
import { Toast } from '@capacitor/toast';
import { Dialog } from '@capacitor/dialog';
import { Share } from '@capacitor/share';
import {
	NativeSettings,
	AndroidSettings,
	IOSSettings
} from 'capacitor-native-settings';
import { App } from '@capacitor/app';

/**
 * Show toast message
 * @param {object} options - Options for the toast message
 */
export const showToast = async (options) => {
	await Toast.show(options);
};

/**
 * Show alert dialog
 * @param {object} options - Options for the alert dialog
 */
export const showAlert = async (options) => {
	await Dialog.alert(options);
};

/**
 * Show prompt dialog
 * @param {object} options - Options for the prompt dialog
 * @returns {Promise} - Promise resolving with the prompt result
 */
export const showPrompt = async (options) => {
	return await Dialog.prompt(options);
};

/**
 * Show prompt dialog
 * @param {object} options - Options for the prompt dialog
 * @returns {Promise} - Promise resolving with the prompt result
 */
export const showConfirm = async (options) => {
	return await Dialog.confirm(options);
};

/**
 * Event listener for back button Android
 */
export function addPhoneBackButtonListener(callback) {
	App.addListener('backButton', () => {
		callback();
	});
}

/**
 * Get device id
 * @returns {string} device id
 */
export const getDeviceId = async () => {
	const info = await Device.getId();
	return info.uuid;
};

/**
 * Get current position
 * @returns {object} coordinates
 */
export const getCurrentPosition = async (options) => {
	const coordinates = await Geolocation.getCurrentPosition();
	if (coordinates) {
		return coordinates;
	}
};

/**
 * Get permission status position
 * @returns {object} permission status
 */
export const checkPermissions = async () => {
	const permission = await Geolocation.checkPermissions();

	return permission;
};

/**
 * Request location permission status
 * @returns {object} permission status
 */
export const requestPermissions = async () => {
	const permission =
		await Geolocation.requestPermissions(/*permissions?: GeolocationPluginPermissions | undefined*/);
	return permission;
};

/**
 * Get current network status
 * @returns {object} status
 */
export const logCurrentNetworkStatus = async () => {
	const status = await Network.getStatus();
	return status;
};

/**Share */
export async function shareURL(options) {
	await Capacitor.Plugins.Share.share({
		title: options.title,
		text: options.text,
		url: options.url
	});
}

/**
 * Open app settings
 */
export function openAppSettings() {
	NativeSettings.open({
		optionAndroid: AndroidSettings.ApplicationDetails,
		optionIOS: IOSSettings.App
	});
}
