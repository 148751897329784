<template>
	<div class="d-flex justify-content-center">
		<div
			class="col-11 col-sm-9 col-md-7 col-lg-6 col-xl-5 text-center p-0 mt-2 mb-0 "
		>
			<div class="px-0 pt-0 pb-0 mt-3 mb-3">
				<form id="form">
					<ul class="progressbar d-flex justify-content-center">
						<li class="step1 active"></li>
						<li
							class="step2"
							:class="step >= 1 ? 'active' : 'notactive'"
						></li>
						<li
							class="step3"
							:class="step >= 2 ? 'active' : 'notactive'"
						></li>
					</ul>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		step() {
			return parseInt(this.$route.params.step);
		}
	}
};
</script>

<style scoped>
* {
	margin: 0;
	padding: 0;
}

#form {
	text-align: center;
	position: relative;

}

.finish {
	text-align: center;
}

.progressbar {

	overflow: hidden;
	color: lightgrey;
}

.progressbar li {
	list-style-type: none;
	font-size: 15px;
	width: 25%;
	float: left;
	position: relative;
	font-weight: 400;
}

.progressbar .step1:before {
	content: '1';
}

.progressbar .step2:before {
	content: '2';
}

.progressbar .step3:before {
	content: '3';
}

.progressbar li:before {
	width: 30px;
	height: 30px;
	line-height: 26px;
	display: block;
	font-size: 20px;
	color: #ffffff;
	background: #d9d9d9;
	border-radius: 50%;
	margin: 6px auto 10px auto;
	padding: 2px;
}

.progressbar li:after {
	content: '';
	width: 100%;
	height: 8px;
	background: #d9d9d9;
	align-items: baseline;
	position: absolute;
	left: 0;
	top: 17px;
	z-index: -1;
}

.progressbar .notactive::before {
	color: #000000;
}
.progressbar li.active:before,
.progressbar li.active:after {
	background: #22c55e;
}

.active.step1::after {
	width: 50%;
	margin-left: 56%;
}

.progressbar .step3::after {
	width: 50%;
}
</style>
