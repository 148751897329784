import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import './assets/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import i18n from './locales/config.js';
import ViewHeader from './components/ViewHeader.vue';
import Region from '../src/components/region.vue';
import { Icon } from '@iconify/vue';
import BaseLayout from './components/BaseLayout.vue';
import VueEasyLightbox from 'vue-easy-lightbox';
import spinner from './components/spinner.vue';

const pinia = createPinia();
const app = createApp(App);

app.component('ViewHeader', ViewHeader);
app.component('Region', Region);
app.component('Icon', Icon);
app.component('BaseLayout', BaseLayout);
app.component('spinner', spinner);
app.use(i18n);
app.use(pinia);
app.use(router);
app.use(VueEasyLightbox);


app.mount('#app');
