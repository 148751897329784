<template>
	<BaseLayout v-if="showDetails">
		<template #header
			><ViewHeader
				:title="$t('earthquakedetails.title')"
				:showback="true"
				:share="true"
			>
			</ViewHeader>
		</template>
		<template #main>
			<div class="body_content">
				<div class="map">
					<MapComp
						v-if="earthquake"
						:marker="earthquake.geometry.coordinates"
						:properties="earthquake.properties"
						:recenterTop="true"
						:showMarker="true"
					>
					</MapComp>
				</div>

				<div v-if="earthquake" class="overlay">
					<div class="scale">
						<div class="nrscale px-3">
							{{ earthquake.properties.mag }}
						</div>
						<div class="textscale">
							<Icon class="iconscale me-2" icon="ri:pulse-line" />
							{{ $t('earthquakedetails.richter') }}
						</div>
					</div>

					<div class="container">
						<div class="row details py-2">
							<div class="col-6">
								<div class="d-flex">
									<Icon
										class="icon me-3"
										icon="ri:map-pin-2-line"
									/>
									<div class="card-body">
										<div class="label-details">
											{{
												$t('earthquakedetails.location')
											}}
										</div>
										<div class="fw-bold data">
											{{
												earthquake.properties
													.location_city
											}}
										</div>
										<div
											v-if="
												earthquake.properties
													.location_distance
											"
											class="data"
										>
											{{
												earthquake.properties
													.location_distance
											}}
										</div>
									</div>
								</div>
							</div>

							<div class="col-6">
								<div class="d-flex">
									<Icon
										class="icon me-3"
										icon="ri:arrow-down-line"
									/>
									<div class="card-body">
										<div class="label-details">
											{{ $t('earthquakedetails.depth') }}
										</div>
										<div class="fw-bold data">
											{{ earthquake.properties.depth }} km
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="row details py-1">
							<div class="col-6">
								<div class="d-flex">
									<Icon
										class="icon me-3"
										icon="ri:timer-2-line"
									/>
									<div class="card-body">
										<div class="label-details">
											{{ $t('earthquakedetails.time') }}
										</div>
										<div class="fw-bold data">
											{{
												formatDate(
													earthquake.properties.time
												)
											}}
										</div>
									</div>
								</div>
							</div>

							<div class="col-6">
								<div class="d-flex">
									<Icon
										class="icon me-3"
										icon="ri:road-map-line"
									/>
									<div class="card-body">
										<div class="label-details">
											{{
												$t(
													'earthquakedetails.coordinates'
												)
											}}
										</div>
										<div class="fw-bold data">
											{{
												getMarkerCoordinates(
													earthquake.geometry
														.coordinates
												)
											}}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="row details py-1">
							<div class="col-6">
								<div class="d-flex">
									<Icon
										class="icon me-3"
										icon="ri:refresh-line"
									/>
									<div class="card-body">
										<div class="label-details">
											{{
												$t(
													'earthquakedetails.last_update'
												)
											}}
										</div>
										<div class="fw-bold data">
											{{
												formatDate(
													earthquake.properties
														.lastupdate
												)
											}}
										</div>
									</div>
								</div>
							</div>

							<div class="col-6">
								<div class="d-flex">
									<Icon
										class="icon me-3"
										icon="ri:edit-box-line"
									/>
									<div class="card-body">
										<div class="label-details">
											{{
												$t(
													'earthquakedetails.event_type'
												)
											}}
										</div>
										<div class="fw-bold data">
											{{
												earthquake.properties.event_type
											}}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div v-if="!hasSurveyStored" class="py-3">
							<RouterLink
								class="btn fw-bold w-100"
								:to="{ name: getChild, params: { step: 0 } }"
							>
								{{ $t('earthquakedetails.felt') }}
							</RouterLink>
						</div>
						<div v-else class="py-3">
							<button disabled="true" class="btn fw-bold w-100">
								{{ $t('earthquakedetails.submitted') }}
							</button>
						</div>
					</div>
				</div>
			</div>
			<spinner :show="isLoading" />
		</template>
	</BaseLayout>
	<RouterView />
</template>

<script>
import MapComp from '../components/MapComp.vue';
import { GetEarthquakesDetails } from '../dataservice';
import { useSettingsStore } from '../stores/settingsStore';
import { mapActions } from 'pinia';
import spinner from '../components/spinner.vue';

export default {
	components: {
		MapComp,
		spinner
	},
	props: {
		id: { type: String, required: true }
	},
	data() {
		return {
			earthquakeID: [],
			earthquakes: [],
			center: [],
			hasSurveyStored: false,
			isLoading: false,
			showSpinner: true
		};
	},
	computed: {
		getChild() {
			return this.$route.matched[this.$route.matched.length - 1]
				.children[0].name;
		},
		earthquake() {
			return this.earthquakes.find((earth) => {
				return earth.id === this.id;
			});
		},
		showDetails() {
			return (
				this.$route.name === 'earthquakeDetailsTimeline' ||
				this.$route.name === 'earthquakeDetailsMap'
			);
		}
	},
	created() {
		this.hasSurveyStored = this.hasSubmittedSurvey(this.id);
		this.loadData();
	},
	updated() {
		this.hasSurveyStored = this.hasSubmittedSurvey(this.id);
	},
	methods: {
		...mapActions(useSettingsStore, ['hasSubmittedSurvey']),
		loadData() {
			this.isLoading = true;
			GetEarthquakesDetails(this.id)
				.then((response) => {
					this.earthquakes = response.data.features;
					this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		formatDate(time) {
			const date = new Date(time);
			let options = {
				day: 'numeric',
				month: 'short',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric',
				hour12: true
			};
			return new Intl.DateTimeFormat('en-GB', options).format(date);
		},
		getMarkerCoordinates() {
			return [...this.earthquake.geometry.coordinates].reverse();
		}
	}
};
</script>

<style scoped>
.body_content {
	height: 100%;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
}
.map {
	max-height: calc(100% - 300px);
	height: 100%;
	min-height: 250px;
}
.overlay {
	background-color: #ffffff;
}
.scale {
	width: 100%;
	border-bottom: 1px solid #d7d1d1;
	align-items: center;
	margin-bottom: 5px;
	display: flex;
	justify-content: center;
}
.nrscale {
	font-size: 37px;
}
.textscale {
	font-size: 22px;
}
.details {
	padding-bottom: 10px;
}
.icon {
	font-size: 30px;
	margin-top: 7px;
}
.iconscale {
	font-size: 35px;
	color: #3d3d3d;
}
.label-details {
	font-size: 20px;
	line-height: 1.2;
	margin-bottom: 7px;
}
.data {
	font-size: 18px;
}
.btn {
	background-color: #162a50;
	color: #ffffff;
	font-size: 21px;
}

.btn:hover {
	background-color: #162a50;
	color: #ffffff;
}
.btn:disabled {
	background-color: #162a50;
	color: #ffffff;
}

@media screen and (max-width: 319px) {
	.iconscale {
		font-size: 22px;
	}

	.nrscale {
		font-size: 30px;
	}

	.textscale {
		font-size: 17px;
	}

	.icon {
		font-size: 18px !important;
		min-width: 18px;
	}

	.label-details {
		font-size: 15px;
	}

	.data {
		font-size: 11px;
	}

	.btn {
		font-size: 15px;
	}
}

@media screen and (min-width: 320px) and (max-width: 374px) {
	.iconscale,
	.nrscale {
		font-size: 30px;
	}

	.textscale {
		font-size: 20px;
	}

	.icon {
		font-size: 21px !important;
		min-width: 21px;
	}

	.label-details {
		font-size: 16px;
	}

	.data {
		font-size: 12px;
	}

	.btn {
		font-size: 17px;
	}
}

@media screen and (min-width: 375px) and (max-width: 425px) {
	.nrscale {
		font-size: 35px;
	}

	.iconscale {
		font-size: 33px;
	}

	.textscale {
		font-size: 20px;
	}

	.icon {
		font-size: 22px !important;
		min-width: 22px;
	}

	.label-details {
		font-size: 18px;
	}

	.data {
		font-size: 14px;
	}

	.btn {
		font-size: 18px;
	}
}

@media screen and (min-width: 426px) and (max-width: 448px) {
	.nrscale {
		font-size: 33px;
	}
	.iconscale {
		font-size: 30px;
	}
	.textscale {
		font-size: 20px;
	}
	.icon {
		font-size: 28px !important;
		min-width: 28px;
	}
	.label-details {
		font-size: 19px;
	}
	.data {
		font-size: 14px;
	}
	.btn {
		font-size: 18px;
	}
}

@media screen and (min-width: 449px) and (max-width: 470px) {
	.nrscale {
		font-size: 36px;
	}
	.iconscale {
		font-size: 28px;
	}
	.textscale {
		font-size: 22px;
	}
	.icon {
		font-size: 28px !important;
		min-width: 28px;
	}
	.label-details {
		font-size: 20px;
	}
	.data {
		font-size: 15px;
	}
	.btn {
		font-size: 19px;
	}
}

@media screen and (min-width: 471px) {
	.nrscale {
		font-size: 36px;
	}
	.iconscale {
		font-size: 35px;
	}
	.textscale {
		font-size: 25px;
	}
	.icon {
		font-size: 28px !important;
		min-width: 28px;
	}
	.label-details {
		font-size: 22px;
	}
	.data {
		font-size: 15px;
	}
	.btn {
		font-size: 19px;
	}
}
</style>
