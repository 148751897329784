<template>
	<p class="survey-text">
		{{ $t('ifeltanearthquake.surveyStep1.text') }}
	</p>

	<form class="form py-3 px-4">
		<div class="inputGroup" @click="geUserLocation()">
			<input
				id="radio1"
				name="radio"
				type="radio"
				:checked="currentlocation"
				disabled
			/>

			<label for="radio1"
				>{{ $t('ifeltanearthquake.surveyStep1.radioButton1') }}
			</label>

			<div
				v-if="survey.location && currentlocation"
				class="selected-location"
			>
				{{ $t('ifeltanearthquake.surveyStep1.selections') }}
				<br />
				{{ survey.location[0].toFixed(3) }} ,
				{{ survey.location[1].toFixed(3) }}.
			</div>
		</div>

		<div class="inputGroup" @click.prevent="showModal = true">
			<input
				id="radio2"
				name="radio"
				type="radio"
				:checked="!currentlocation && selected"
			/>
			<Teleport to="body">
				<modalmap
					:show="showModal"
					@return-data="setOtherLocation"
					@close="showModal = false"
				></modalmap>
			</Teleport>
			<label for="radio2">{{
				$t('ifeltanearthquake.surveyStep1.radioButton2')
			}}</label>

			<div
				v-if="survey.location && !currentlocation"
				class="selected-location"
			>
				{{ $t('ifeltanearthquake.surveyStep1.selections') }}
				<br />
				{{ survey.location[0].toFixed(3) }} ,
				{{ survey.location[1].toFixed(3) }}.
			</div>
		</div>
	</form>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import modalmap from '../components/modalmap.vue';
import {
	getCurrentPosition,
	checkPermissions,
	requestPermissions,
	openAppSettings,
	showConfirm,
	showToast
	// Capacitor
} from '../device-utils.js';

export default {
	components: {
		modalmap
	},
	props: {
		selected: { type: Array, required: true, default: () => [] },
		survey: {
			type: Object,
			default: () => {
				return {};
			}
		}
	},
	emits: ['selected'],
	data() {
		return {
			showModal: false,
			center: [41.3317875, 19.7948969],
			currentlocation: false,
			userlocation: null,
			permission: {
				location: null,
				coarseLocation: null
			},
			requestPermission: null
		};
	},
	created() {},
	methods: {
		async geUserLocation() {
			try {
				const permissions = await checkPermissions();

				if (permissions.location === 'granted') {
					this.getCurrentPositionOpen();
				} else {
					const platform = Capacitor.getPlatform();
					if (platform === 'web') {
						this.getCurrentPositionOpen();
					} else {
						this.askForPermission();
					}
				}
			} catch (error) {
				let message = 'Please enable location services on settings.';
				this.currentlocation = false;
				showToast({ text: message, duration: 'long' });
			}
		},

		async askForPermission() {
			const confirm = await showConfirm({
				title: 'Earthquakes App needs permission to use location',
				message:
					'Earthquakes app needs permission to use location for displaying your location on the map through Google Maps or OpenStreetMap service.',
				okButtonTitle: 'Open Settings',
				cancelButtonTitle: 'Cancel'
			});
			if (confirm.value) {
				openAppSettings();
			}
		},

		getCurrentPositionOpen() {
			getCurrentPosition()
				.then((data) => {
					this.userlocation = [
						data.coords.latitude,
						data.coords.longitude
					];
					if (this.userlocation) {
						this.currentlocation = true;

						this.center = this.userlocation;
						this.$emit('selected', {
							location: this.userlocation,
							currentlocation: this.currentlocation
						});
					}
				})
				.catch((err) => {
					let message = 'location off message';
					this.currentlocation = false;
					showToast({ text: message, duration: 'long' });
				});
		},

		setOtherLocation(mapdata) {
			this.currentlocation = false;
			this.center = [mapdata.center.lat, mapdata.center.lng];
			this.$emit('selected', {
				location: this.center,
				currentlocation: this.currentlocation
			});
		}
	}
};
</script>

<style scoped>
.form {
	background-color: #dfe0e2;
	border-radius: 10px;
	font-size: 18px;
	font-weight: 500;
	margin: 35px auto;
	max-width: 543px;
}

.survey-text {
	color: white;
	font-size: 17px;
	text-align: center;
	margin: 0px 28px;
}

/* white labels */
.inputGroup {
	background-color: #fff;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	margin: 20px 0;
	max-width: 500px; /* set the width of the input group */
}

/* into white labels */
.inputGroup label {
	color: black;
	max-width: 100%;
	padding: 12px;
	position: relative;
	text-align: left;
	transition: color 200ms ease-in;
	z-index: 2;
}

/* radiobox-i */
.inputGroup label:after {
	background-color: #fff;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
	background-position: 0px 1px;
	border: 2px solid #d1d7dc;
	border-radius: 50%;
	content: '';
	height: 28px;
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	transition: all 200ms ease-in;
	width: 28px;
	z-index: 2;
}

.inputGroup input:checked ~ label:after {
	background-color: #22c55e;
	border-color: #16a34a;
}

.inputGroup input {
	height: 32px;
	position: absolute;
	visibility: hidden;
	width: 32px;
	z-index: 2;
}

.selected-location {
	font-weight: 400;
	font-size: 17px;
	position: relative;
	text-align: center;
	margin-bottom: 10px;
	max-width: 500px; /* set the width of the input group */
}

@media screen and (min-width: 320px) and (max-width: 354px) {
	.inputGroup label:after {
		background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
		height: 21px;
		width: 21px;
	}

	.inputGroup,
	.selected-location {
		max-width: 220px;
	}

	.form {
		font-size: 13px;
		max-width: 270px;
	}
	.selected-location {
		font-size: 14px;
	}
}

@media screen and (min-width: 355px) and (max-width: 375px) {
	.inputGroup label:after {
		background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
		background-position: 1px 1px;
		height: 23px;
		width: 23px;
	}

	.inputGroup,
	.selected-location {
		max-width: 290px;
	}

	.form {
		font-size: 14px;
		max-width: 320px;
	}
	.selected-location {
		font-size: 14px;
	}
}

@media screen and (min-width: 376px) and (max-width: 425px) {
	.inputGroup label:after {
		background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
		background-position: 0px 2px;
		height: 25px;
		width: 25px;
	}

	.inputGroup,
	.selected-location {
		max-width: 305px;
	}

	.form {
		font-size: 14px;
		max-width: 350px;
	}
	.selected-location {
		font-size: 15px;
	}
}

@media screen and (min-width: 426px) and (max-width: 530px) {
	.inputGroup label:after {
		background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
		background-position: 0px 2px;
		height: 25px;
		width: 25px;
	}

	.inputGroup,
	.selected-location {
		max-width: 350px;
	}

	.form {
		font-size: 16px;
		max-width: 400px;
	}
	.selected-location {
		font-size: 16px;
	}
}

@media screen and (min-width: 531px) and (max-width: 600px) {
	.inputGroup label:after {
		background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
		background-position: 0px 2px;
		height: 25px;
		width: 25px;
	}

	.inputGroup,
	.selected-location {
		max-width: 430px;
	}

	.form {
		font-size: 18px;
		max-width: 480px;
	}
	.selected-location {
		font-size: 17px;
	}
}
</style>
