import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { watch } from 'vue';
import { PostEarthquakeSurvey } from '../dataservice.js';
export const useSettingsStore = defineStore('settings', () => {
	const language = useLocalStorage('lang', 'sq');
	const terms = useLocalStorage('terms', false);

	const hasCheckedTerms = () => {
		return (terms.value = true);
	};

	const i18nInstance = useI18n();

	const changeLanguage = (langcode) => {
		language.value = langcode;
	};

	watch(language, (newLang) => {
		i18nInstance.locale.value = newLang;
	});

	//const surveyTemplate = { id: null, felt: null, location, device_id : null, date: null }
	const surveyStored = useLocalStorage('surveys', []);
	/**
	 * Store a survey on the server and on localstorage
	 * @param {*} id
	 * @param {*} felt
	 * @param {*} location
	 * @param {*} device_id
	 */
	const storeSurvey = async (id, felt, location, device_id) => {
		const newsurvey = { id, felt, location, device_id };
		console.log(newsurvey);

		// Call server here...
		await PostEarthquakeSurvey(newsurvey);
		surveyStored.value.push(newsurvey);
	};
	const getSurvey = (id) => {
		return surveyStored.value.find((s) => s.id === id);
	};
	const hasSubmittedSurvey = (id) => {
		return surveyStored.value.find((s) => s.id === id) ? true : false;
	};

	return {
		language,
		terms,
		hasCheckedTerms,
		changeLanguage,
		surveyStored,
		storeSurvey,
		hasSubmittedSurvey,
		getSurvey
	};
});
